import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  MarkEmailRead as MarkReadIcon,
  MarkunreadOutlined as UnreadIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

export function Alerts({ plantId, onSeeAll }) {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await axios.get(
          `https://l11rpwsolh.execute-api.us-east-1.amazonaws.com/Prod/notifications?plant_id=${plantId}`
        );
        setAlerts(response.data.notifications);
      } catch (error) {
        console.error('Error fetching alerts:', error);
        setError('Failed to load alerts');
      } finally {
        setLoading(false);
      }
    };

    fetchAlerts();
  }, [plantId]);

  const getAlertLevelStyle = (alertLevel) => {
    switch (alertLevel) {
      case 1:
        return 'bg-red-100 text-red-800';
      case 2:
        return 'bg-orange-100 text-orange-800';
      case 3:
        return 'bg-yellow-100 text-yellow-800';
      case 4:
        return 'bg-blue-100 text-blue-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getAlertLevelText = (alertLevel) => {
    switch (alertLevel) {
      case 1:
        return 'CRITICAL';
      case 2:
        return 'HIGH';
      case 3:
        return 'MEDIUM';
      case 4:
        return 'LOW';
      default:
        return 'INFO';
    }
  };

  const handleMarkRead = async (alertId) => {
    try {
      await axios.patch(
        `https://l11rpwsolh.execute-api.us-east-1.amazonaws.com/Prod/notifications?notification_id=${alertId}`
      );
      setAlerts(alerts.map(alert => 
        alert.id === alertId ? { ...alert, is_read: true } : alert
      ));
    } catch (error) {
      console.error('Error marking alert as read:', error);
    }
  };

  const handleMarkUnread = async (alertId) => {
    try {
      await axios.patch(
        `https://l11rpwsolh.execute-api.us-east-1.amazonaws.com/Prod/notifications?notification_id=${alertId}`,
        { is_read: false }
      );
      setAlerts(alerts.map(alert => 
        alert.id === alertId ? { ...alert, is_read: false } : alert
      ));
    } catch (error) {
      console.error('Error marking alert as unread:', error);
    }
  };

  const handleDelete = async (alertId) => {
    try {
      await axios.delete(
        `https://l11rpwsolh.execute-api.us-east-1.amazonaws.com/Prod/notifications?notification_id=${alertId}`
      );
      setAlerts(alerts.filter(alert => alert.id !== alertId));
    } catch (error) {
      console.error('Error deleting alert:', error);
    }
  };

  if (loading) {
    return <div className="text-center py-4">Loading alerts...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center py-4">{error}</div>;
  }

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Recent Alerts</h3>
        <button
          onClick={onSeeAll}
          className="text-sm text-[#B8860B] hover:text-[#DAA520]"
        >
          See All
        </button>
      </div>

      <div className="space-y-4">
        {alerts.map((alert) => (
          <div key={alert.id} className="bg-gray-50 rounded-lg p-4 shadow-sm">
            <div className="flex justify-between">
              <div className="space-y-1">
                <div className="flex items-center gap-2">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${getAlertLevelStyle(alert.alert_level)}`}
                  >
                    {getAlertLevelText(alert.alert_level)}
                  </span>
                  {!alert.is_read && (
                    <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                  )}
                </div>
                <div className="text-sm text-gray-900">{alert.description}</div>
                <div className="text-xs text-gray-500">
                  {new Date(alert.created_at).toLocaleString()}
                </div>
              </div>
              <div className="flex items-start gap-2">
                {!alert.is_read ? (
                  <button
                    onClick={() => handleMarkRead(alert.id)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <MarkReadIcon className="h-5 w-5" />
                  </button>
                ) : (
                  <button
                    onClick={() => handleMarkUnread(alert.id)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <UnreadIcon className="h-5 w-5" />
                  </button>
                )}
                <button
                  onClick={() => handleDelete(alert.id)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <DeleteIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
        {alerts.length === 0 && (
          <div className="text-center text-gray-500">No alerts available</div>
        )}
      </div>
    </div>
  );
} 