import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Circle, AlertTriangle, XCircle, Search, ChevronUp, ChevronDown, SlidersHorizontal, ChevronRight, ChevronLeft, Menu, X } from 'lucide-react';
import { SolarPanelIcon } from '../components/icons/SolarPanelIcon';
import { FarmIcon } from '../components/icons/FarmIcon';
import { PlantIcon } from '../components/icons/PlantIcons';
import { DeviceIcon } from '../components/icons/DeviceIcon';
import { getAuthorizedPlants } from '../utils/accessControl';
import { DownloadMultiPlantReport } from '../components/reports/DownloadMultiPlantReport';

const PlantDashboard = ({ user }) => {
  const navigate = useNavigate();
  const [plants, setPlants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [sortField, setSortField] = useState('alertLevel');
  const [sortDirection, setSortDirection] = useState('asc');
  const [statusFilter, setStatusFilter] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [performanceFilter, setPerformanceFilter] = useState({
    min: '',
    max: ''
  });
  const [alertLevelFilter, setAlertLevelFilter] = useState([]);
  const [showConnectedOnly, setShowConnectedOnly] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const API_URL = 'https://49bgmmmqnk.execute-api.us-east-1.amazonaws.com/prod';

  // Add headers definition
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  // Status options for filtering
  const statusOptions = [
    { value: 'Ok', label: 'Connected', color: 'text-green-500', icon: Circle },
    { value: 'Warning', label: 'Warning', color: 'text-yellow-500', icon: AlertTriangle },
    { value: 'CommunicationMonitoringFault', label: 'Disconnected', color: 'text-orange-500', icon: AlertTriangle },
    { value: 'Error', label: 'Error', color: 'text-red-500', icon: XCircle }
  ];

  // Add alert level options constant
  const alertLevelOptions = [
    { value: 0, label: 'Normal', color: 'text-green-500' },
    { value: 1, label: 'Communication Issue', color: 'text-gray-500' },
    { value: 2, label: 'Critical', color: 'text-red-500' },
    { value: 3, label: 'Performance Warning', color: 'text-yellow-500' }
  ];

  // Add this helper function near the top of the component, with the other utility functions
  const formatPerformanceRatio = (ratio, hasBenchmarkData) => {
    // First check if we have the ratio
    if (!ratio || ratio === 'N/A') return 'N/A';
    
    // Then check if we have benchmark data
    if (hasBenchmarkData === false) return 'NBD';
    
    const parsed = parseFloat(ratio);
    return isNaN(parsed) ? 'N/A' : `${(parsed * 100).toFixed(1)}%`;
  };

  // Add this helper function to check recent generation
  const hasRecentGeneration = (dailyAverageActual) => {
    return dailyAverageActual && parseFloat(dailyAverageActual) > 0;
  };

  // Fetch plants data
  useEffect(() => {
    const fetchPlants = async () => {
      try {
        setLoading(true);
        
        // Get authorized plants for current user
        const authorizedPlants = await getAuthorizedPlants();

        const response = await axios.get(`${API_URL}/plants`);
        const plantsData = typeof response.data === 'string' 
          ? JSON.parse(response.data) 
          : response.data;

        // Filter plants based on user access
        const authorizedPlantsData = authorizedPlants.includes('*') 
          ? plantsData // If user has wildcard access, show all plants
          : plantsData.filter(plant => authorizedPlants.includes(plant.plantId));

        // Fetch performance data only for authorized plants
        const plantsWithDetails = await Promise.all(authorizedPlantsData.map(async plant => {
          try {
            const performanceResponse = await axios.get(
              `https://n395eo29z2.execute-api.us-east-1.amazonaws.com/prod/plants/30day/${plant.plantId}`,
              { headers }
            );
            
            const performanceData = performanceResponse.data;

            // Determine status based on original status and recent generation
            let effectiveStatus = plant.status;
            if (effectiveStatus === 'Unknown') {
              effectiveStatus = hasRecentGeneration(performanceData.dailyAverageActual) 
                ? 'Ok' 
                : 'Warning';
            }

            // Fetch measurements data
            const measurementsResponse = await axios.get(
              `${API_URL}/plants/${plant.plantId}/measurements`,
              { headers }
            );
            
            const measurements = measurementsResponse.data;
            console.log('Fetched measurements for plant:', plant.plantId, measurements);

            return {
              id: plant.plantId,
              plantId: plant.plantId,
              name: plant.name,
              description: plant.description,
              timezone: plant.timezone,
              status: effectiveStatus,
              performanceRatio: performanceData.performanceRatio,
              // Only set hasBenchmarkData to false if it's explicitly false
              hasBenchmarkData: performanceData.hasBenchmarkData !== false,
              dailyAverageActual: performanceData.dailyAverageActual,
              location: plant.location,
              measurements: {
                day: measurements.day,
                month: measurements.month,
                year: measurements.year,
                recent: measurements.recent
              },
              devices: plant.devices
            };
          } catch (error) {
            console.error(`Error fetching performance data for plant ${plant.plantId}:`, error);
            return {
              id: plant.plantId,
              plantId: plant.plantId,
              name: plant.name,
              description: plant.description,
              timezone: plant.timezone,
              status: plant.status || 'Unknown',
              performanceRatio: plant.performanceRatio,
              hasBenchmarkData: false,
              dailyAverageActual: null,
              location: plant.location,
              measurements: plant.measurements,
              devices: plant.devices
            };
          }
        }));

        setPlants(plantsWithDetails);
      } catch (error) {
        console.error('Error fetching plant data:', error);
        setPlants([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPlants();
  }, []);

  // Calculate status counts
  const statusCounts = useMemo(() => {
    return plants.reduce((acc, plant) => {
      acc[plant.status] = (acc[plant.status] || 0) + 1;
      return acc;
    }, {});
  }, [plants]);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Ok':
        return <Circle className="h-4 w-4 text-green-500 fill-current" />;
      case 'Warning':
        return <AlertTriangle className="h-4 w-4 text-yellow-500" />;
      case 'CommunicationMonitoringFault':
        return <AlertTriangle className="h-4 w-4 text-orange-500" />;
      case 'Error':
        return <XCircle className="h-4 w-4 text-red-500" />;
      case 'Unknown':
      default:
        return <AlertTriangle className="h-4 w-4 text-gray-400" />;
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'Ok':
        return 'Connected';
      case 'Warning':
        return 'Warning';
      case 'CommunicationMonitoringFault':
        return 'Disconnected';
      case 'Error':
        return 'Error';
      case 'Unknown':
      default:
        return 'Unknown';
    }
  };

  const getStatusTextColor = (status) => {
    switch (status) {
      case 'Ok':
        return 'text-green-700';
      case 'Warning':
        return 'text-yellow-700';
      case 'CommunicationMonitoringFault':
        return 'text-orange-700';
      case 'Error':
        return 'text-red-700';
      default:
        return 'text-gray-700';
    }
  };

  // Add this function to calculate alert level
  const getSiteAlertLevel = (plant) => {
    // Check for zero total generation (0.00)
    if (plant.totalGenerated === "0.00") {
      return 1; // Communication Issue
    }
    
    // Performance ratio checks
    const perfRatio = parseFloat(plant.performanceRatio);
    if (!isNaN(perfRatio)) {
      if (perfRatio < 0.80) {
        return 2; // Critical
      }
      if (perfRatio >= 0.80 && perfRatio < 0.93) {
        return 3; // Performance Warning
      }
    }
    
    return 0; // Normal (good standing) - changed from 5 to 0
  };

  // Add this function to get alert level color
  const getAlertLevelColor = (level) => {
    switch (level) {
      case 0:
        return 'text-green-500';  // Normal
      case 1:
        return 'text-gray-500';   // Communication Issue
      case 2:
        return 'text-red-500';    // Critical
      case 3:
        return 'text-yellow-500'; // Performance Warning
      default:
        return 'text-gray-400';
    }
  };

  const filteredAndSortedPlants = useMemo(() => {
    // Define statusPriority inside the memo
    const statusPriority = {
      'Error': 1,
      'CommunicationMonitoringFault': 2,
      'Warning': 3,
      'Unknown': 4,
      'Ok': 5
    };

    return plants
      .filter(plant => {
        // Search filter
        const matchesSearch = plant.name.toLowerCase().includes(search.toLowerCase());
        
        // Status filter
        const matchesStatus = statusFilter.length === 0 || statusFilter.includes(plant.status);
        
        // Performance filter
        const performance = plant.performanceRatio * 100;
        const matchesPerformance = (
          (performanceFilter.min === '' || performance >= parseFloat(performanceFilter.min)) &&
          (performanceFilter.max === '' || performance <= parseFloat(performanceFilter.max))
        );

        // Connected only filter
        const matchesConnected = !showConnectedOnly || plant.status === 'Ok';

        // Alert level filter
        const matchesAlertLevel = alertLevelFilter.length === 0 || 
          alertLevelFilter.includes(getSiteAlertLevel(plant));

        return matchesSearch && matchesStatus && matchesPerformance && 
               matchesConnected && matchesAlertLevel;
      })
      .sort((a, b) => {
        if (sortField === 'name') {
          return sortDirection === 'asc' 
            ? a.name.localeCompare(b.name) 
            : b.name.localeCompare(a.name);
        }
        if (sortField === 'status') {
          const priorityA = statusPriority[a.status] || 999;
          const priorityB = statusPriority[b.status] || 999;
          return sortDirection === 'asc'
            ? priorityA - priorityB
            : priorityB - priorityA;
        }
        if (sortField === 'performance') {
          const perfA = a.performanceRatio || 0;
          const perfB = b.performanceRatio || 0;
          return sortDirection === 'asc'
            ? perfA - perfB
            : perfB - perfA;
        }
        if (sortField === 'totalGenerated') {
          const genA = a.totalGenerated || 0;
          const genB = b.totalGenerated || 0;
          return sortDirection === 'asc'
            ? genA - genB
            : genB - genA;
        }
        if (sortField === 'alertLevel') {
          const levelA = getSiteAlertLevel(a);
          const levelB = getSiteAlertLevel(b);
          // Update priority order: Critical (2) > Performance Warning (3) > Communication Issue (1) > Normal (0)
          const getPriority = (level) => {
            switch (level) {
              case 2: return 0; // Critical - highest priority
              case 3: return 1; // Performance Warning
              case 1: return 2; // Communication Issue
              case 0: return 3; // Normal - lowest priority
              default: return 4;
            }
          };
          const priorityA = getPriority(levelA);
          const priorityB = getPriority(levelB);
          return sortDirection === 'asc'
            ? priorityB - priorityA  // Reversed to show Critical first in ascending
            : priorityA - priorityB;
        }
        if (sortField === 'expectedTotal') {
          const expA = a.expectedTotal || 0;
          const expB = b.expectedTotal || 0;
          return sortDirection === 'asc'
            ? expA - expB
            : expB - expA;
        }
        return 0;
      });
  }, [
    plants, 
    search, 
    statusFilter, 
    sortField, 
    sortDirection, 
    performanceFilter, 
    showConnectedOnly, 
    alertLevelFilter
  ]);

  // Add handler for filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    // Only allow numbers and empty string
    if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
      setPerformanceFilter(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // Add this helper function to get site level alert counts
  const getSiteLevelAlertCounts = (plants) => {
    return plants.reduce((acc, plant) => {
      const alertLevel = getSiteAlertLevel(plant);
      switch (alertLevel) {
        case 2: // Critical
          acc.critical++;
          break;
        case 3: // Performance Warning
          acc.warning++;
          break;
        case 0: // Normal
          acc.normal++;
          break;
        default:
          break;
      }
      return acc;
    }, { critical: 0, warning: 0, normal: 0 });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[calc(100vh-4rem)]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Plants Dashboard</h1>
        {plants && plants.length > 0 && (
          <DownloadMultiPlantReport plants={plants} />
        )}
      </div>
      {/* Summaries Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {/* Communication Status Summary */}
        <div className="bg-white rounded-lg shadow-sm p-4">
          <h3 className="text-sm font-medium text-gray-700 mb-3">Communication Status</h3>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            <div className="flex items-center gap-2">
              <Circle className="h-4 w-4 text-green-500 fill-current" />
              <div>
                <div className="text-sm font-medium">{statusCounts['Ok'] || 0}</div>
                <div className="text-xs text-gray-500">Connected</div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <AlertTriangle className="h-4 w-4 text-yellow-500" />
              <div>
                <div className="text-sm font-medium">{statusCounts['Warning'] || 0}</div>
                <div className="text-xs text-gray-500">Warning</div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <AlertTriangle className="h-4 w-4 text-orange-500" />
              <div>
                <div className="text-sm font-medium">{statusCounts['CommunicationMonitoringFault'] || 0}</div>
                <div className="text-xs text-gray-500">Disconnected</div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <XCircle className="h-4 w-4 text-red-500" />
              <div>
                <div className="text-sm font-medium">{statusCounts['Error'] || 0}</div>
                <div className="text-xs text-gray-500">Error</div>
              </div>
            </div>
          </div>
        </div>

        {/* Site Level Alerts Summary */}
        <div className="bg-white rounded-lg shadow-sm p-4">
          <h3 className="text-sm font-medium text-gray-700 mb-3">Site Level Alerts</h3>
          <div className="grid grid-cols-3 gap-4">
            <div className="flex items-center gap-2">
              <PlantIcon className="h-4 w-4 text-red-500" />
              <div>
                <div className="text-sm font-medium">{getSiteLevelAlertCounts(plants).critical}</div>
                <div className="text-xs text-gray-500">Critical</div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <PlantIcon className="h-4 w-4 text-yellow-500" />
              <div>
                <div className="text-sm font-medium">{getSiteLevelAlertCounts(plants).warning}</div>
                <div className="text-xs text-gray-500">Performance</div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <PlantIcon className="h-4 w-4 text-green-500" />
              <div>
                <div className="text-sm font-medium">{getSiteLevelAlertCounts(plants).normal}</div>
                <div className="text-xs text-gray-500">Normal</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-white rounded-xl shadow-sm">
        {/* Search and Filters */}
        <div className="p-4 border-b border-gray-100">
          {/* Desktop view */}
          <div className="hidden md:flex flex-col sm:flex-row gap-4">
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search plants..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                className="flex items-center justify-center w-10 h-10 border border-gray-200 rounded-lg hover:bg-gray-50"
                title={isMenuCollapsed ? "Expand" : "Collapse"}
              >
                {isMenuCollapsed ? (
                  <ChevronRight className="h-5 w-5" />
                ) : (
                  <ChevronLeft className="h-5 w-5" />
                )}
              </button>
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="flex items-center gap-2 px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50"
              >
                <SlidersHorizontal className="h-5 w-5" />
                <span>Filters</span>
              </button>
            </div>
          </div>

          {/* Mobile view */}
          <div className="md:hidden flex items-center gap-2">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="flex items-center justify-center w-10 h-10 border border-gray-200 rounded-lg"
            >
              <Menu className="h-5 w-5" />
            </button>
            <div className="relative flex-1">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search plants..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>

          {/* Mobile menu */}
          {isMobileMenuOpen && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-40 md:hidden">
              <div className="fixed inset-y-0 right-0 max-w-xs w-full bg-white shadow-xl z-50">
                <div className="p-4">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg font-medium">Filters</h3>
                    <button
                      onClick={() => setIsMobileMenuOpen(false)}
                      className="p-2 rounded-lg hover:bg-gray-100"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                  
                  {/* Mobile filters */}
                  <div className="space-y-4">
                    {/* Status filter */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Communication Status
                      </label>
                      <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-lg max-h-[200px] overflow-y-auto">
                        {statusOptions.map((option) => {
                          const Icon = option.icon;
                          return (
                            <label key={option.value} className="flex items-center gap-2 cursor-pointer">
                              <input
                                type="checkbox"
                                checked={statusFilter.includes(option.value)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setStatusFilter(prev => [...prev, option.value]);
                                  } else {
                                    setStatusFilter(prev => prev.filter(v => v !== option.value));
                                  }
                                }}
                                className="w-4 h-4 border border-gray-300 rounded accent-[#B8860B]"
                              />
                              <div className="flex items-center gap-2">
                                <Icon className={`h-5 w-5 ${option.color} ${option.value === 'Ok' ? 'fill-current' : ''}`} />
                                <span className="text-sm text-gray-700">{option.label}</span>
                              </div>
                            </label>
                          );
                        })}
                      </div>
                    </div>

                    {/* Performance filter */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Performance Range
                      </label>
                      <div className="p-2 border border-gray-200 rounded-lg h-[125px] flex flex-col justify-between">
                        <div className="relative h-8">
                          {/* Range slider track */}
                          <div className="absolute h-1 w-full bg-gray-200 rounded-full top-1/2 -translate-y-1/2" />

                          {/* Active range highlight */}
                          <div
                            className="absolute h-1 bg-yellow-500 rounded-full top-1/2 -translate-y-1/2"
                            style={{
                              left: `${performanceFilter.min || 0}%`,
                              width: `${(performanceFilter.max || 100) - (performanceFilter.min || 0)}%`
                            }}
                          />

                          {/* Range sliders */}
                          <input
                            type="range"
                            name="min"
                            min="0"
                            max="100"
                            value={performanceFilter.min || 0}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              if (value <= (performanceFilter.max || 100)) {
                                setPerformanceFilter(prev => ({
                                  ...prev,
                                  min: value
                                }));
                              }
                            }}
                            className="range-input range-input-min"
                          />

                          <input
                            type="range"
                            name="max"
                            min="0"
                            max="100"
                            value={performanceFilter.max || 100}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              if (value >= (performanceFilter.min || 0)) {
                                setPerformanceFilter(prev => ({
                                  ...prev,
                                  max: value
                                }));
                              }
                            }}
                            className="range-input range-input-max"
                          />
                        </div>

                        {/* Number inputs - centered with reduced spacing */}
                        <div className="flex items-center justify-center gap-6">
                          <div className="flex items-center gap-1">
                            <span className="text-sm text-gray-600">Min</span>
                            <input
                              type="number"
                              name="min"
                              value={performanceFilter.min}
                              onChange={handleFilterChange}
                              placeholder="0"
                              min="0"
                              max="100"
                              className="w-14 px-2 py-1 border border-gray-300 rounded text-sm focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                            />
                          </div>
                          <div className="flex items-center gap-1">
                            <span className="text-sm text-gray-600">Max</span>
                            <input
                              type="number"
                              name="max"
                              value={performanceFilter.max}
                              onChange={handleFilterChange}
                              placeholder="100"
                              min="0"
                              max="100"
                              className="w-14 px-2 py-1 border border-gray-300 rounded text-sm focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Alert Level filter */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Alert Level
                      </label>
                      <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-lg max-h-[200px] overflow-y-auto">
                        {alertLevelOptions.map((option) => (
                          <label key={option.value} className="flex items-center gap-2 cursor-pointer">
                            <input
                              type="checkbox"
                              checked={alertLevelFilter.includes(option.value)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setAlertLevelFilter(prev => [...prev, option.value]);
                                } else {
                                  setAlertLevelFilter(prev => prev.filter(v => v !== option.value));
                                }
                              }}
                              className="w-4 h-4 border border-gray-300 rounded accent-[#B8860B]"
                            />
                            <div className="flex items-center gap-2">
                              <PlantIcon className={`h-5 w-5 ${option.color}`} />
                              <span className="text-sm text-gray-700">{option.label}</span>
                            </div>
                          </label>
                        ))}
                      </div>
                    </div>

                    {/* Reset button */}
                    {(performanceFilter.min || performanceFilter.max || 
                      statusFilter.length > 0 || alertLevelFilter.length > 0) && (
                      <div className="flex justify-center mt-4">
                        <button
                          onClick={() => {
                            setPerformanceFilter({ min: '', max: '' });
                            setStatusFilter([]);
                            setAlertLevelFilter([]);
                          }}
                          className="text-sm text-[#B8860B] hover:text-[#DAA520]"
                        >
                          Reset All Filters
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Desktop filters */}
          {isFilterOpen && (
            <div className="hidden md:block mt-4">
              <div className="grid grid-cols-3 gap-4">
                {/* Status filter */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Communication Status
                  </label>
                  <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-lg h-[125px] overflow-y-auto">
                    {statusOptions.map((option) => {
                      const Icon = option.icon;
                      return (
                        <label key={option.value} className="flex items-center gap-2 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={statusFilter.includes(option.value)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setStatusFilter(prev => [...prev, option.value]);
                              } else {
                                setStatusFilter(prev => prev.filter(v => v !== option.value));
                              }
                            }}
                            className="w-4 h-4 border border-gray-300 rounded accent-[#B8860B]"
                          />
                          <div className="flex items-center gap-2">
                            <Icon className={`h-5 w-5 ${option.color} ${option.value === 'Ok' ? 'fill-current' : ''}`} />
                            <span className="text-sm text-gray-700">{option.label}</span>
                          </div>
                        </label>
                      );
                    })}
                  </div>
                </div>

                {/* Performance filter */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Performance Range
                  </label>
                  <div className="p-2 border border-gray-200 rounded-lg h-[125px] flex flex-col justify-between">
                    <div className="relative h-8">
                      {/* Range slider track */}
                      <div className="absolute h-1 w-full bg-gray-200 rounded-full top-1/2 -translate-y-1/2" />

                      {/* Active range highlight */}
                      <div
                        className="absolute h-1 bg-yellow-500 rounded-full top-1/2 -translate-y-1/2"
                        style={{
                          left: `${performanceFilter.min || 0}%`,
                          width: `${(performanceFilter.max || 100) - (performanceFilter.min || 0)}%`
                        }}
                      />

                      {/* Range sliders */}
                      <input
                        type="range"
                        name="min"
                        min="0"
                        max="100"
                        value={performanceFilter.min || 0}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (value <= (performanceFilter.max || 100)) {
                            setPerformanceFilter(prev => ({
                              ...prev,
                              min: value
                            }));
                          }
                        }}
                        className="range-input range-input-min"
                      />

                      <input
                        type="range"
                        name="max"
                        min="0"
                        max="100"
                        value={performanceFilter.max || 100}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (value >= (performanceFilter.min || 0)) {
                            setPerformanceFilter(prev => ({
                              ...prev,
                              max: value
                            }));
                          }
                        }}
                        className="range-input range-input-max"
                      />
                    </div>

                    {/* Number inputs - centered with reduced spacing */}
                    <div className="flex items-center justify-center gap-6">
                      <div className="flex items-center gap-1">
                        <span className="text-sm text-gray-600">Min</span>
                        <input
                          type="number"
                          name="min"
                          value={performanceFilter.min}
                          onChange={handleFilterChange}
                          placeholder="0"
                          min="0"
                          max="100"
                          className="w-14 px-2 py-1 border border-gray-300 rounded text-sm focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                        />
                      </div>
                      <div className="flex items-center gap-1">
                        <span className="text-sm text-gray-600">Max</span>
                        <input
                          type="number"
                          name="max"
                          value={performanceFilter.max}
                          onChange={handleFilterChange}
                          placeholder="100"
                          min="0"
                          max="100"
                          className="w-14 px-2 py-1 border border-gray-300 rounded text-sm focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <style>{`
                  .range-input {
                    -webkit-appearance: none;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: none;
                    margin: 0;
                    padding: 0;
                  }

                  .range-input-min {
                    z-index: 3;
                  }

                  .range-input-max {
                    z-index: 2;
                  }

                  .range-input::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    pointer-events: auto;
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    background: #EAB308;
                    border: 2px solid white;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                    cursor: pointer;
                    margin-top: -5px;
                  }

                  .range-input::-moz-range-thumb {
                    pointer-events: auto;
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    background: #EAB308;
                    border: 2px solid white;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                    cursor: pointer;
                  }

                  /* Remove any default styles */
                  .range-input::-webkit-slider-runnable-track {
                    height: 1px;
                    background: transparent;
                    border: none;
                  }

                  .range-input::-moz-range-track {
                    height: 1px;
                    background: transparent;
                    border: none;
                  }

                  input[type="range"]:focus {
                    outline: none;
                  }

                  /* Hide spinner buttons for number inputs */
                  input[type="number"]::-webkit-inner-spin-button,
                  input[type="number"]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                  input[type="number"] {
                    -moz-appearance: textfield;
                  }
                `}</style>

                {/* Alert Level filter */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Alert Level
                  </label>
                  <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-lg h-[125px] overflow-y-auto">
                    {alertLevelOptions.map((option) => (
                      <label key={option.value} className="flex items-center gap-2 cursor-pointer">
                        <input
                          type="checkbox"
                          checked={alertLevelFilter.includes(option.value)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setAlertLevelFilter(prev => [...prev, option.value]);
                            } else {
                              setAlertLevelFilter(prev => prev.filter(v => v !== option.value));
                            }
                          }}
                          className="w-4 h-4 border border-gray-300 rounded accent-[#B8860B]"
                        />
                        <div className="flex items-center gap-2">
                          <PlantIcon className={`h-5 w-5 ${option.color}`} />
                          <span className="text-sm text-gray-700">{option.label}</span>
                        </div>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              {/* Reset Filters button - centered below the filters */}
              {(performanceFilter.min || performanceFilter.max || 
                statusFilter.length > 0 || alertLevelFilter.length > 0) && (
                <div className="flex justify-center mt-4">
                  <button
                    onClick={() => {
                      setPerformanceFilter({ min: '', max: '' });
                      setStatusFilter([]);
                      setAlertLevelFilter([]);
                    }}
                    className="text-sm text-[#B8860B] hover:text-[#DAA520]"
                  >
                    Reset All Filters
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-gray-100">
                <th className="text-left p-4">
                  <button
                    onClick={() => {
                      if (sortField === 'name') {
                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                      } else {
                        setSortField('name');
                        setSortDirection('asc');
                      }
                    }}
                    className="flex items-center gap-1 font-medium text-gray-700 hover:text-gray-900"
                  >
                    {!isMenuCollapsed && 'Name'}
                    {sortField === 'name' && (
                      sortDirection === 'asc' ? (
                        <ChevronUp className="h-4 w-4" />
                      ) : (
                        <ChevronDown className="h-4 w-4" />
                      )
                    )}
                  </button>
                </th>
                {!isMenuCollapsed && (
                  <>
                    <th className="text-left p-4">
                      <button
                        onClick={() => {
                          if (sortField === 'status') {
                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                          } else {
                            setSortField('status');
                            setSortDirection('asc');
                          }
                        }}
                        className="flex items-center gap-1 font-medium text-gray-700 hover:text-gray-900"
                      >
                        Communication Status
                        {sortField === 'status' && (
                          sortDirection === 'asc' ? (
                            <ChevronUp className="h-4 w-4" />
                          ) : (
                            <ChevronDown className="h-4 w-4" />
                          )
                        )}
                      </button>
                    </th>
                    <th className="text-left p-4">
                      <button
                        onClick={() => {
                          if (sortField === 'performance') {
                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                          } else {
                            setSortField('performance');
                            setSortDirection('asc');
                          }
                        }}
                        className="flex items-center gap-1 font-medium text-gray-700 hover:text-gray-900"
                      >
                        Performance
                        {sortField === 'performance' && (
                          sortDirection === 'asc' ? (
                            <ChevronUp className="h-4 w-4" />
                          ) : (
                            <ChevronDown className="h-4 w-4" />
                          )
                        )}
                      </button>
                    </th>
                    <th className="text-left p-4">
                      <button
                        onClick={() => {
                          if (sortField === 'alertLevel') {
                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                          } else {
                            setSortField('alertLevel');
                            setSortDirection('asc');
                          }
                        }}
                        className="flex items-center gap-1 font-medium text-gray-700 hover:text-gray-900"
                      >
                        Alert Level
                        {sortField === 'alertLevel' && (
                          sortDirection === 'asc' ? (
                            <ChevronUp className="h-4 w-4" />
                          ) : (
                            <ChevronDown className="h-4 w-4" />
                          )
                        )}
                      </button>
                    </th>
                    <th className="text-left p-4">Timezone</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedPlants.map((plant) => (
                <tr
                  key={plant.id}
                  onClick={() => navigate(`/plant/${plant.id}`)}
                  className="border-b border-gray-100 cursor-pointer hover:bg-gray-50"
                >
                  <td className="p-4">
                    <div className="font-medium text-gray-900">
                      {isMenuCollapsed ? plant.name.substring(0, 15) + '...' : plant.name}
                    </div>
                  </td>
                  {!isMenuCollapsed && (
                    <>
                      <td className="p-4">
                        <div className="flex items-center gap-2">
                          {getStatusIcon(plant.status)}
                          <span className={`text-sm ${getStatusTextColor(plant.status)}`}>
                            {getStatusText(plant.status)}
                          </span>
                        </div>
                      </td>
                      <td className="p-4">
                        <div className="text-gray-600">
                          {formatPerformanceRatio(plant.performanceRatio, plant.hasBenchmarkData)}
                        </div>
                      </td>
                      <td className="p-4 w-40">
                        <div className="flex items-center justify-center gap-2 mx-auto">
                          <PlantIcon 
                            className={`h-5 w-5 ${getAlertLevelColor(getSiteAlertLevel(plant))}`}
                          />
                          <DeviceIcon 
                            className="h-5 w-5 text-green-500"
                          />
                        </div>
                      </td>
                      <td className="p-4">
                        <div className="text-gray-600">{plant.timezone}</div>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          {filteredAndSortedPlants.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500">No plants found matching your criteria</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlantDashboard;