import React, { useState, useEffect } from 'react';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Plus, X, Check, AlertCircle } from 'lucide-react';
import axios from 'axios';
import { isAdmin } from '../utils/accessControl';

// Add API_URL constant
const API_URL = 'https://49bgmmmqnk.execute-api.us-east-1.amazonaws.com/prod';

// Only one default export
export default function AdminPanel() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [plants, setPlants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [showAddUser, setShowAddUser] = useState(false);
  const [error, setError] = useState(null);

  // Fetch all plants for the dropdown
  useEffect(() => {
    const fetchPlants = async () => {
      try {
        const response = await axios.get(`${API_URL}/plants`);
        setPlants(response.data);
      } catch (error) {
        console.error('Error fetching plants:', error);
      }
    };

    fetchPlants();
  }, []);

  // Check if current user is admin
  useEffect(() => {
    const checkAdmin = async () => {
      const isUserAdmin = await isAdmin();
      if (!isUserAdmin) {
        navigate('/');
      }
    };
    checkAdmin();
  }, [navigate]);

  const handleAddUser = async () => {
    if (!newEmail) return;
    
    try {
      // Add new user to the access control
      // In a real app, this would be an API call
      setUsers([...users, {
        email: newEmail,
        plants: []
      }]);
      setNewEmail('');
      setShowAddUser(false);
    } catch (error) {
      setError('Failed to add user');
    }
  };

  const handleTogglePlantAccess = async (userEmail, plantId) => {
    try {
      // Toggle plant access for user
      // In a real app, this would be an API call
      setUsers(users.map(user => {
        if (user.email === userEmail) {
          const hasAccess = user.plants.includes(plantId);
          return {
            ...user,
            plants: hasAccess 
              ? user.plants.filter(id => id !== plantId)
              : [...user.plants, plantId]
          };
        }
        return user;
      }));
    } catch (error) {
      setError('Failed to update access');
    }
  };

  return (
    <div className="p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">User Access Management</h1>
          <button
            onClick={() => setShowAddUser(true)}
            className="flex items-center gap-2 px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
          >
            <Plus className="h-5 w-5" />
            Add User
          </button>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg flex items-center gap-2 text-red-700">
            <AlertCircle className="h-5 w-5" />
            {error}
          </div>
        )}

        {/* User List */}
        <div className="bg-white rounded-lg shadow">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b">
                  <th className="text-left p-4">User Email</th>
                  <th className="text-left p-4">Authorized Plants</th>
                  <th className="text-left p-4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <tr key={user.email} className="border-b">
                    <td className="p-4">{user.email}</td>
                    <td className="p-4">
                      <div className="flex flex-wrap gap-2">
                        {plants.map(plant => (
                          <button
                            key={plant.plantId}
                            onClick={() => handleTogglePlantAccess(user.email, plant.plantId)}
                            className={`px-3 py-1 rounded-full text-sm ${
                              user.plants.includes(plant.plantId)
                                ? 'bg-green-100 text-green-800'
                                : 'bg-gray-100 text-gray-800'
                            }`}
                          >
                            {plant.name}
                          </button>
                        ))}
                      </div>
                    </td>
                    <td className="p-4">
                      <button
                        onClick={() => {/* Handle delete user */}}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Add User Modal */}
        {showAddUser && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Add New User</h2>
                <button onClick={() => setShowAddUser(false)}>
                  <X className="h-5 w-5" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    User Email
                  </label>
                  <input
                    type="email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg"
                    placeholder="user@example.com"
                  />
                </div>
                
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => setShowAddUser(false)}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAddUser}
                    className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
                  >
                    Add User
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 