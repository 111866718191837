import React, { useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { Alerts } from './Alerts';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const chartRefs = {
  dayChart: null,
  monthChart: null,
  yearChart: null
};

const baseOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        padding: 0
      },
      offset: true,
      stacked: false,
      barPercentage: 0.9,
      categoryPercentage: 0.8
    },
    y: {
      display: false,
      grid: {
        color: 'rgba(0, 0, 0, 0.1)',
        drawTicks: false,
      },
      ticks: {
        padding: 0
      }
    },
  },
  layout: {
    padding: 0
  }
};

// Simplify the bar settings
const actualBarSettings = {
  maxBarThickness: 30,
  borderRadius: 4
};

const benchmarkBarSettings = {
  maxBarThickness: 4,
  borderRadius: 2
};

export function GenerationStats({ measurements, systemInfo, plantId }) {
  console.log('GenerationStats measurements:', measurements);
  console.log('GenerationStats systemInfo:', systemInfo);
  const dayChartRef = useRef(null);
  const monthChartRef = useRef(null);
  const yearChartRef = useRef(null);

  React.useEffect(() => {
    chartRefs.dayChart = dayChartRef.current;
    chartRefs.monthChart = monthChartRef.current;
    chartRefs.yearChart = yearChartRef.current;
  }, [dayChartRef.current, monthChartRef.current, yearChartRef.current]);

  const getCurrentPower = () => {
    if (!measurements?.day?.set || measurements.day.set.length === 0) {
      return 0;
    }
    
    const latestReading = measurements.day.set[measurements.day.set.length - 1];
    return parseFloat(latestReading.pvGeneration);
  };

  const formatDayChartData = (measurements) => {
    if (!measurements?.day?.set) return null;

    const chartData = measurements.day.set.map(item => {
      const date = new Date(item.time);
      return {
        x: date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        }).replace(':00', ''),
        y: parseFloat(item.pvGeneration) / 1000,
        fullDate: date
      };
    });

    return {
      labels: chartData.map(item => item.x),
      datasets: [{
        data: chartData.map(item => item.y),
        fullDates: chartData.map(item => item.fullDate),
        borderColor: '#FFD700',
        backgroundColor: 'rgba(255, 215, 0, 0.1)',
        tension: 0.4,
        fill: true,
        pointRadius: 0
      }]
    };
  };

  const formatMonthChartData = (measurements) => {
    if (!measurements?.month?.set) return null;

    const chartData = measurements.month.set.map(item => {
      const date = new Date(item.time);
      return {
        x: date.getDate().toString(),
        y: parseFloat(item.pvGeneration) / 1000,
        fullDate: date
      };
    });

    // Get the current month's benchmark
    const currentMonth = new Date().getMonth();
    const monthNames = ['january', 'february', 'march', 'april', 'may', 'june', 
                       'july', 'august', 'september', 'october', 'november', 'december'];
    const monthlyBenchmark = systemInfo?.monthly?.[monthNames[currentMonth]] || 0;
    
    // Calculate daily benchmark
    const daysInMonth = new Date(new Date().getFullYear(), currentMonth + 1, 0).getDate();
    const dailyBenchmark = monthlyBenchmark / daysInMonth;

    return {
      labels: chartData.map(item => item.x),
      datasets: [
        {
          type: 'bar',
          data: chartData.map(item => item.y),
          fullDates: chartData.map(item => item.fullDate),
          backgroundColor: '#FFD700',
          ...actualBarSettings,
          order: 2
        },
        {
          type: 'line',
          data: new Array(daysInMonth).fill(dailyBenchmark),
          borderColor: 'rgba(156, 163, 175, 0.7)',
          borderWidth: 2,
          pointRadius: 0,
          fill: false,
          order: 1
        }
      ]
    };
  };

  const formatYearChartData = (measurements) => {
    if (!measurements?.year?.set) return null;

    const chartData = measurements.year.set.map(item => {
      const date = new Date(item.time);
      return {
        x: date.toLocaleString('default', { month: 'short' }),
        y: parseFloat(item.pvGeneration) / 1000,
        fullDate: date
      };
    });

    const monthlyBenchmarks = [
      systemInfo?.monthly?.january || 0,
      systemInfo?.monthly?.february || 0,
      systemInfo?.monthly?.march || 0,
      systemInfo?.monthly?.april || 0,
      systemInfo?.monthly?.may || 0,
      systemInfo?.monthly?.june || 0,
      systemInfo?.monthly?.july || 0,
      systemInfo?.monthly?.august || 0,
      systemInfo?.monthly?.september || 0,
      systemInfo?.monthly?.october || 0,
      systemInfo?.monthly?.november || 0,
      systemInfo?.monthly?.december || 0,
    ];

    return {
      labels: chartData.map(item => item.x),
      datasets: [
        {
          type: 'bar',
          label: 'Actual Generation',
          data: chartData.map(item => item.y),
          fullDates: chartData.map(item => item.fullDate),
          backgroundColor: '#FFD700',
          ...actualBarSettings,
          order: 1
        },
        {
          type: 'bar',
          label: 'Expected Generation',
          data: monthlyBenchmarks,
          backgroundColor: 'rgba(156, 163, 175, 0.7)',
          ...benchmarkBarSettings,
          order: 2
        }
      ]
    };
  };

  const getChartOptions = (type) => ({
    ...baseOptions,
    plugins: {
      ...baseOptions.plugins,
      legend: {
        display: type === 'year', // Only show legend for year chart
        position: 'top',
        align: 'end',
        labels: {
          boxWidth: 12,
          usePointStyle: true,
          pointStyle: 'line'
        }
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const dataset = context[0].dataset;
            const index = context[0].dataIndex;
            const date = dataset.fullDates?.[index];
            
            if (!date) {
              return type === 'year' && context[0].dataset.type === 'line' 
                ? 'Monthly Benchmark'
                : '';
            }
            
            switch (type) {
              case 'day':
                return date.toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true
                });
              case 'month':
                return date.toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric'
                });
              case 'year':
                return date.toLocaleDateString('en-US', {
                  month: 'long',
                  year: 'numeric'
                });
              default:
                return '';
            }
          },
          label: function(context) {
            return `${context.raw.toLocaleString('en-US', { 
              minimumFractionDigits: 2, 
              maximumFractionDigits: 2 
            })} kWh`;
          }
        }
      }
    }
  });

  const calculateTotal = (data, type) => {
    if (!data?.set) return 0;
    
    // Always divide by 1000 to convert W to kW
    const total = data.set.reduce(
      (sum, item) => sum + (parseFloat(item.pvGeneration) / 1000), 
      0
    );
    
    return total.toLocaleString('en-US', { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 
    });
  };

  // Add this helper function to trim hours after current time
  const trimFutureHours = (data) => {
    const currentHour = new Date().getHours();
    
    // If we have hourly data, trim everything after current hour
    if (data && data.labels && data.labels.length === 24) {
      return {
        labels: data.labels.slice(0, currentHour + 1),
        datasets: data.datasets.map(dataset => ({
          ...dataset,
          data: dataset.data.slice(0, currentHour + 1)
        }))
      };
    }
    return data;
  };

  // Modify the day data preparation
  const dayData = measurements?.day?.set ? {
    labels: measurements.day.set.map(m => {
      const hour = new Date(m.time).getHours();
      return new Date(m.time).toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true
      }).replace(':00', '');
    }),
    datasets: [{
      data: measurements.day.set.map(m => parseFloat(m.pvGeneration) / 1000),
      borderColor: '#FFD700',
      backgroundColor: 'rgba(255, 215, 0, 0.1)',
      tension: 0.4,
      fill: true,
      pointRadius: 0
    }]
  } : null;

  // Trim the day data before rendering
  const trimmedDayData = trimFutureHours(dayData);

  const monthData = formatMonthChartData(measurements);
  const yearData = formatYearChartData(measurements);
  const maxPower = systemInfo?.devices?.[0]?.generatorPower || 100000;

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-6">Generation Statistics</h2>

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-gray-50 rounded-xl p-6">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-medium text-gray-900">Today</h3>
              <span className="text-sm text-gray-600">
                Total: {calculateTotal(measurements.day, 'day')} kWh
              </span>
            </div>
            {trimmedDayData ? (
              <div className="h-48">
                <Line 
                  ref={dayChartRef} 
                  options={getChartOptions('day')} 
                  data={trimmedDayData} 
                />
              </div>
            ) : (
              <div className="h-48 flex items-center justify-center text-gray-500">
                No data available
              </div>
            )}
          </div>

          <div className="bg-gray-50 rounded-xl p-6">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-medium text-gray-900">This Month</h3>
              <span className="text-sm text-gray-600">
                Total: {calculateTotal(measurements.month, 'month')} kWh
              </span>
            </div>
            {monthData ? (
              <div className="h-48">
                <Bar 
                  ref={monthChartRef} 
                  options={getChartOptions('month')} 
                  data={monthData} 
                />
              </div>
            ) : (
              <div className="h-48 flex items-center justify-center text-gray-500">
                No data available
              </div>
            )}
          </div>

          <div className="bg-gray-50 rounded-xl p-6">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-medium text-gray-900">This Year</h3>
              <span className="text-sm text-gray-600">
                Total: {calculateTotal(measurements.year, 'year')} kWh
              </span>
            </div>
            {yearData ? (
              <div className="h-48">
                <Bar 
                  ref={yearChartRef} 
                  options={getChartOptions('year')} 
                  data={yearData} 
                />
              </div>
            ) : (
              <div className="h-48 flex items-center justify-center text-gray-500">
                No data available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}