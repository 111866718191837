import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import kwLogo from '../../assets/KW TOOLBOX_logo v1.png';
import { SolarPanelIcon } from '../icons/SolarPanelIcon';
import axios from 'axios';

ChartJS.register(ArcElement, Tooltip, Legend);

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    borderBottom: '1 solid #E5E7EB',
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  plantHeader: {
    fontSize: 18,
    marginBottom: 10,
    color: '#374151',
    borderBottom: '1 solid #E5E7EB',
    paddingBottom: 5,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
    padding: 4,
  },
  label: {
    fontSize: 12,
    color: '#6B7280',
  },
  value: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  performanceSection: {
    marginTop: 10,
    padding: 8,
    backgroundColor: '#F3F4F6',
    borderRadius: 4,
  },
  generationTitle: {
    fontSize: 14,
    marginBottom: 5,
    color: '#374151',
    fontWeight: 'bold',
  },
  generationValue: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1F2937',
  },
  devicesList: {
    marginTop: 10,
  },
  deviceItem: {
    marginBottom: 5,
    padding: 5,
    backgroundColor: '#F9FAFB',
  },
  reportDate: {
    fontSize: 10,
    color: '#6B7280',
    textAlign: 'center',
    marginBottom: 20,
  },
  summarySection: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: '#F9FAFB',
    borderRadius: 4,
  },
  summaryTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#374151',
  },
  chartContainer: {
    marginTop: 10,
    marginBottom: 10,
    height: 120,
    padding: 10,
  },
  chartBar: {
    backgroundColor: '#FFD700',
    position: 'absolute',
    bottom: 20,
    width: 15,
  },
  chartLabel: {
    position: 'absolute',
    bottom: 0,
    fontSize: 8,
    textAlign: 'center',
    width: 20,
  },
  chartAxisLabel: {
    position: 'absolute',
    left: 0,
    bottom: 5,
    fontSize: 8,
    color: '#6B7280',
  },
  chartGrid: {
    position: 'absolute',
    left: 25,
    right: 10,
    bottom: 20,
    height: 80,
    borderLeft: '1 solid #E5E7EB',
    borderBottom: '1 solid #E5E7EB',
  },
  barContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
    height: 20,
  },
  barLabel: {
    width: '30%',
    fontSize: 8,
    color: '#374151',
    marginRight: 8,
  },
  performanceBar: {
    height: 16,
    borderRadius: 2,
    justifyContent: 'center',
    paddingHorizontal: 4,
  },
  barValue: {
    fontSize: 8,
    color: '#FFFFFF',
    textAlign: 'right',
  },
  portfolioSection: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: '#F9FAFB',
    borderRadius: 4,
  },
  portfolioTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#374151',
  },
  pieChartContainer: {
    alignItems: 'center',
    marginVertical: 20,
  },
  pieChartVisual: {
    width: 120,
    height: 120,
    borderRadius: 60,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  pieSegment: {
    height: '100%',
  },
  pieLegend: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
    gap: 20,
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 10,
  },
  legendColor: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginRight: 5,
  },
  legendText: {
    fontSize: 10,
    color: '#374151',
  },
  chartTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#374151',
  }
});

// Add this new component for bar charts
const BarChart = ({ data, maxValue }) => {
  const normalizedData = data.map(item => ({
    ...item,
    normalizedValue: (item.value / maxValue) * 80 // 80 is the height of chart grid
  }));

  return (
    <View style={styles.chartContainer}>
      <View style={styles.chartGrid}>
        {normalizedData.map((item, index) => (
          <View key={index} style={{
            ...styles.chartBar,
            height: item.normalizedValue,
            left: 25 + (index * 20),
          }}>
            <Text style={{
              ...styles.chartLabel,
              left: -2.5,
            }}>
              {item.label}
            </Text>
          </View>
        ))}
      </View>
      <Text style={styles.chartAxisLabel}>{maxValue.toFixed(1)}kW</Text>
    </View>
  );
};

// Add this new component for pie chart
const PortfolioPieChart = ({ plants }) => {
  // Calculate performance ratio categories
  const performanceCategories = plants.reduce((acc, plant) => {
    if (!plant.performanceRatio) return acc;
    const ratio = plant.performanceRatio * 100;
    
    if (ratio >= 90) acc.high++;
    else if (ratio >= 80) acc.medium++;
    else acc.low++;
    
    return acc;
  }, { high: 0, medium: 0, low: 0 });

  const total = plants.filter(p => p.performanceRatio).length;

  // Calculate percentages
  const highPercent = (performanceCategories.high / total * 100).toFixed(1);
  const mediumPercent = (performanceCategories.medium / total * 100).toFixed(1);
  const lowPercent = (performanceCategories.low / total * 100).toFixed(1);

  const chartData = {
    labels: ['>90%', '80-90%', '<80%'],
    datasets: [{
      data: [highPercent, mediumPercent, lowPercent],
      backgroundColor: [
        '#22C55E',  // green
        '#EAB308',  // yellow
        '#EF4444'   // red
      ],
      borderWidth: 0
    }]
  };

  const chartOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <View style={styles.pieChartContainer}>
      <Text style={styles.chartTitle}>Portfolio Performance Summary</Text>
      
      {/* Use circles for the pie chart representation */}
      <div className="w-48 h-48 relative">
        <Doughnut data={chartData} options={chartOptions} />
      </div>
      
      {/* Legend */}
      <View style={styles.pieLegend}>
        <View style={styles.legendItem}>
          <View style={[styles.legendColor, { backgroundColor: '#22C55E' }]} />
          <Text style={styles.legendText}>{'>90% ('}{highPercent}%)</Text>
        </View>
        <View style={styles.legendItem}>
          <View style={[styles.legendColor, { backgroundColor: '#EAB308' }]} />
          <Text style={styles.legendText}>80-90% ({mediumPercent}%)</Text>
        </View>
        <View style={styles.legendItem}>
          <View style={[styles.legendColor, { backgroundColor: '#EF4444' }]} />
          <Text style={styles.legendText}>{'<80% ('}{lowPercent}%)</Text>
        </View>
      </View>
    </View>
  );
};

export function MultiPlantReport({ plants = [], user }) {
  console.log('Plants received in MultiPlantReport:', plants.map(plant => ({
    name: plant.name,
    latitude: plant.latitude,
    longitude: plant.longitude,
    location: plant.location,
    rawPlant: plant
  })));

  const [weatherData, setWeatherData] = useState({
    averageTemp: null,
    conditions: [],
    descriptions: []
  });

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        // Filter plants with valid location data
        const plantsWithLocation = plants.filter(plant => {
          console.log('Checking location for plant:', {
            name: plant.name,
            latitude: plant.latitude,
            longitude: plant.longitude
          });
          
          return (
            plant.latitude && 
            plant.longitude && 
            plant.latitude !== 0 && 
            plant.longitude !== 0
          );
        });
        console.log('Plants with valid location:', plantsWithLocation);

        const weatherPromises = plantsWithLocation.map(plant => {
          const url = `https://api.open-meteo.com/v1/forecast?` + 
            `latitude=${plant.latitude}&` +
            `longitude=${plant.longitude}&` +
            `hourly=temperature_2m&` +
            `temperature_unit=fahrenheit&` +
            `timezone=${encodeURIComponent(plant.timezone || 'UTC')}`;
          
          console.log(`Fetching weather for ${plant.name}:`, url);
          return axios.get(url);
        });

        const weatherResponses = await Promise.all(weatherPromises);
        const validWeatherData = weatherResponses.map(response => {
          const hourlyData = response.data.hourly;
          const currentHourIndex = new Date().getHours();
          return {
            temp: hourlyData.temperature_2m[currentHourIndex]
          };
        });

        // Calculate average temperature
        const totalTemp = validWeatherData.reduce((sum, data) => sum + data.temp, 0);
        const averageTemp = validWeatherData.length > 0 
          ? Math.round(totalTemp / validWeatherData.length)
          : null;

        setWeatherData({
          averageTemp,
          conditions: ['Clear'], // Default since we're only getting temperature
          descriptions: ['Clear sky']
        });

      } catch (error) {
        console.error('Error fetching weather:', error);
        setWeatherData({
          averageTemp: null,
          conditions: ['Unknown'],
          descriptions: ['Weather data unavailable']
        });
      }
    };

    if (plants?.length > 0) {
      fetchWeatherData();
    }
  }, [plants]);

  // Helper function to get weather icons
  const getWeatherIcon = (condition) => {
    switch (condition?.toLowerCase()) {
      case 'clear':
        return '☀️';
      case 'clouds':
        return '☁️';
      case 'rain':
        return '🌧️';
      case 'snow':
        return '🌨️';
      case 'thunderstorm':
        return '⛈️';
      case 'drizzle':
        return '🌦️';
      case 'mist':
      case 'fog':
        return '🌫️';
      default:
        return '☀️';
    }
  };

  // Calculate totals and stats
  const totalSites = plants.length;
  
  // Calculate total energy yesterday
  const totalEnergyYesterday = plants.reduce((total, plant) => {
    if (!plant?.measurements?.day?.set) return total;
    const dailyGeneration = plant.measurements.day.set.reduce((sum, item) => {
      return sum + (parseFloat(item.pvGeneration) || 0);
    }, 0);
    return total + (dailyGeneration / 1000); // Convert to kWh
  }, 0);

  // Calculate average PR
  const plantsWithPR = plants.filter(p => p?.performanceRatio != null);
  const averagePR = plantsWithPR.length > 0 
    ? plantsWithPR.reduce((sum, plant) => sum + (plant.performanceRatio || 0), 0) / plantsWithPR.length * 100
    : 0;

  // Count resolved alerts (you can modify this based on your alert logic)
  const resolvedAlerts = 7;

  // Calculate performance distribution with null checks
  const performanceCategories = plants.reduce((acc, plant) => {
    if (!plant?.performanceRatio) return acc;
    const ratio = plant.performanceRatio * 100;
    
    if (ratio >= 90) acc.high++;
    else if (ratio >= 80) acc.medium++;
    else acc.low++;
    
    return acc;
  }, { high: 0, medium: 0, low: 0 });

  const total = plantsWithPR.length;
  const highPercent = total > 0 ? (performanceCategories.high / total * 100).toFixed(1) : '0.0';
  const mediumPercent = total > 0 ? (performanceCategories.medium / total * 100).toFixed(1) : '0.0';
  const lowPercent = total > 0 ? (performanceCategories.low / total * 100).toFixed(1) : '0.0';

  const chartData = {
    labels: ['>90%', '80-90%', '<80%'],
    datasets: [{
      data: [highPercent, mediumPercent, lowPercent],
      backgroundColor: [
        '#22C55E',  // green
        '#EAB308',  // yellow
        '#EF4444'   // red
      ],
      borderWidth: 0
    }]
  };

  const chartOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      }
    }
  };

  // Calculate communication status counts
  const communicationStatus = plants.reduce((acc, plant) => {
    if (!plant?.status) return acc;
    switch (plant.status.toLowerCase()) {
      case 'ok':
        acc.connected++;
        break;
      case 'warning':
        acc.warning++;
        break;
      case 'error':
        acc.error++;
        break;
      case 'disconnected':
        acc.disconnected++;
        break;
      default:
        break;
    }
    return acc;
  }, { connected: 0, warning: 0, disconnected: 0, error: 0 });

  return (
    <div className="p-8 max-w-5xl mx-auto bg-white shadow-lg rounded-lg">
      {/* Header Section */}
      <div className="border-b pb-6 mb-6">
        <div className="text-red-500 text-xl font-semibold mb-4">EPC Portfolio Report: Daily</div>
        <div className="flex justify-center mb-6">
          <img src={kwLogo} alt="KW Toolbox" className="h-12" />
        </div>
        <h1 className="text-2xl font-bold text-center mb-2">KW Toolbox – Daily Solar Monitoring Report</h1>
        <p className="text-center text-gray-600">
          Hello {user?.attributes?.name || 'Customer'},<br />
          Here's your daily solar performance overview for your monitored portfolio.<br />
          Below, you'll find key stats, alerts, and actionable insights.
        </p>
      </div>

      {/* Quick Portfolio Overview Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-800">Quick Portfolio Overview:</h2>
          <div className="bg-white border rounded-lg overflow-hidden">
            <table className="w-full">
              <tbody>
                <tr className="border-b">
                  <td className="p-3 text-gray-600">Total Sites Monitored</td>
                  <td className="p-3 text-right font-semibold">{totalSites}</td>
                </tr>
                <tr className="border-b bg-gray-50">
                  <td className="p-3 text-gray-600">Total Energy Produced Yesterday:</td>
                  <td className="p-3 text-right font-semibold">
                    {totalEnergyYesterday.toLocaleString(undefined, {
                      maximumFractionDigits: 0
                    })} kWh
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="p-3 text-gray-600">Average Performance Ratio (PR)</td>
                  <td className="p-3 text-right font-semibold">{averagePR.toFixed(1)}%</td>
                </tr>
                <tr className="bg-gray-50">
                  <td className="p-3 text-gray-600">Resolved Alerts</td>
                  <td className="p-3 text-right font-semibold">{resolvedAlerts}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Environmental Insights */}
          <div className="mt-6 bg-gray-50 rounded-lg p-4">
            <h3 className="font-semibold text-gray-800 mb-2">Environmental Insights (previous 24 hrs):</h3>
            <div className="flex items-center gap-2 text-gray-600 mb-1">
              <span>Weather:</span>
              <span className="flex items-center gap-1">
                {weatherData.conditions.length > 0 ? (
                  weatherData.conditions.map((condition, index) => (
                    <span key={index} className="flex items-center">
                      {getWeatherIcon(condition)}
                      <span className="text-sm ml-1">{condition}</span>
                      {index < weatherData.conditions.length - 1 && <span className="mx-1">/</span>}
                    </span>
                  ))
                ) : (
                  <span>Loading weather data...</span>
                )}
              </span>
            </div>
            <div className="text-gray-600">
              Average Temp: {weatherData.averageTemp ? `${weatherData.averageTemp}° F` : 'Loading...'}
            </div>
          </div>
        </div>

        {/* Portfolio Performance Summary */}
        <div>
          <h2 className="text-xl font-semibold mb-4">Portfolio Performance Summary</h2>
          <div className="w-64 h-64 mx-auto">
            <Doughnut data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* Communication Status */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Communication Status</h2>
        <div className="grid grid-cols-4 gap-4">
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
            <span>{communicationStatus.connected} Connected</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <span>{communicationStatus.warning} Warning</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-orange-500"></div>
            <span>{communicationStatus.disconnected} Disconnected</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <span>{communicationStatus.error} Error</span>
          </div>
        </div>
      </div>

      {/* Site Level Alerts */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Site Level Alerts</h2>
        <div className="grid grid-cols-3 gap-4">
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <span>4 Critical</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <span>1 Performance</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
            <span>6 Normal (+90%)</span>
          </div>
        </div>
      </div>

      {/* Device Level Alerts */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Device Level Alerts</h2>
        <div className="grid grid-cols-3 gap-4">
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <span>4 Inverter Failures</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <span>1 Inverter Deviations</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
            <span>6 Normal</span>
          </div>
        </div>
      </div>

      {/* View All Alerts Button */}
      <div className="mt-8 flex justify-center">
        <button className="px-6 py-2 border-2 border-yellow-500 rounded-full text-yellow-500 hover:bg-yellow-500 hover:text-white transition-colors">
          View All Alerts
        </button>
      </div>

      {/* Report Footer */}
      <div className="mt-8 text-center text-sm text-gray-500">
        Report generated: {new Date().toLocaleDateString()}
      </div>
    </div>
  );
} 