import { getCurrentUser } from 'aws-amplify/auth';
import axios from 'axios';

const API_URL = 'https://dse5hlgvae.execute-api.us-east-1.amazonaws.com/default/user-access';

export const getUserAccess = async () => {
  try {
    const { signInDetails } = await getCurrentUser();
    const userEmail = signInDetails.loginId;
    
    console.log('Fetching access for:', userEmail);
    
    const response = await axios.get(`${API_URL}?userEmail=${encodeURIComponent(userEmail)}`);
    
    console.log('Access data:', response.data);
    
    // Make sure we're getting the expected structure
    if (response.data && typeof response.data.isAdmin === 'boolean') {
      return response.data;
    } else {
      console.error('Unexpected response format:', response.data);
      return {
        isAdmin: false,
        authorizedPlants: []
      };
    }
  } catch (error) {
    console.error('Error getting user access:', error);
    console.error('Error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    return {
      isAdmin: false,
      authorizedPlants: []
    };
  }
};

export const isAdmin = async () => {
  try {
    const userAccess = await getUserAccess();
    return userAccess.isAdmin;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

export const hasPlantAccess = async (plantId) => {
  try {
    const userAccess = await getUserAccess();
    return userAccess.authorizedPlants.includes('*') || 
           userAccess.authorizedPlants.includes(plantId);
  } catch (error) {
    console.error('Error checking plant access:', error);
    return false;
  }
};

export const getAuthorizedPlants = async () => {
  try {
    const userAccess = await getUserAccess();
    return userAccess.authorizedPlants;
  } catch (error) {
    console.error('Error getting authorized plants:', error);
    return [];
  }
}; 