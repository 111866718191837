import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export function DebugView() {
  const { plantId } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentTest, setCurrentTest] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [testPlantId, setTestPlantId] = useState(plantId);

  const API_URL = 'https://49bgmmmqnk.execute-api.us-east-1.amazonaws.com/prod';
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  const testEndpoint = async (endpoint, description) => {
    setLoading(true);
    setCurrentTest(description);
    setError(null);
    setResponse(null);

    try {
      console.log(`Testing endpoint: ${endpoint}`);
      
      // For devices endpoint
      if (description === 'Get Devices') {
        const response = await axios({
          method: 'GET',
          url: `${API_URL}/plants/${testPlantId}/devices`,
          headers: {
            'Accept': 'application/json'
          }
        });
        console.log('Full Response:', response);
        setResponse(response.data);
      }
      // For measurements endpoint
      else if (description.includes('Measurements')) {
        const type = description.split(' ')[1].toLowerCase();
        const response = await axios({
          method: 'GET',
          url: `${API_URL}/plants/${testPlantId}/measurements`,
          params: { type },
          headers: {
            'Accept': 'application/json'
          }
        });
        console.log('Full Response:', response);
        setResponse(response.data);
      }
      // For get all plants
      else {
        const response = await axios({
          method: 'GET',
          url: `${API_URL}/plants`,
          headers: {
            'Accept': 'application/json'
          }
        });
        console.log('Full Response:', response);
        setResponse(response.data);
      }

    } catch (error) {
      console.error('Error:', error);
      setError({
        message: error.message,
        endpoint: endpoint,
        response: error.response?.data,
        status: error.response?.status,
        config: error.config // Added to see exact request configuration
      });
    } finally {
      setLoading(false);
    }
  };

  const testBenchmarks = async () => {
    try {
      console.log('Fetching benchmarks...');
      const response = await axios.get(
        'https://zf3urt9bg3.execute-api.us-east-1.amazonaws.com/prod/benchmarks/11547077',
        { headers }
      );
      console.log('Benchmarks Response:', response.data);
      setResponse(response.data);
      setCurrentTest('Benchmarks');
      return response.data;
    } catch (error) {
      console.error('Error fetching benchmarks:', error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setError(error);
    }
  };

  const test30DayPerformance = async (singlePlant = false) => {
    setLoading(true);
    const description = singlePlant ? 'Single Plant 30-Day Performance' : 'All Plants 30-Day Performance';
    setCurrentTest(description);
    setError(null);
    setResponse(null);

    try {
      const endpoint = singlePlant 
        ? `https://n395eo29z2.execute-api.us-east-1.amazonaws.com/prod/plants/30day/${testPlantId}`
        : 'https://n395eo29z2.execute-api.us-east-1.amazonaws.com/prod/plants/30day';
      
      console.log(`Testing endpoint: ${endpoint}`);
      
      const response = await axios({
        method: 'GET',
        url: endpoint,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      console.log('30-Day Performance Response:', response.data);
      setResponse(response.data);
    } catch (error) {
      console.error('Error:', error);
      setError({
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        config: error.config
      });
    } finally {
      setLoading(false);
    }
  };

  const calculate30DayTotals = (data) => {
    if (!data?.set) return null;
    
    const totals = data.set.reduce((acc, day) => {
      return {
        actualTotal: acc.actualTotal + (parseFloat(day.pvGeneration) || 0),
        expectedTotal: acc.expectedTotal + (parseFloat(day.expectedGeneration) || 0)
      };
    }, { actualTotal: 0, expectedTotal: 0 });

    return {
      ...totals,
      performanceRatio: data.performanceRatio,
      plantName: data.plant?.name,
      dcSize: data.benchmarks?.systemInfo?.dcSize
    };
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Debug View for Plant {plantId}</h1>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Test Plant ID:
        </label>
        <input
          type="text"
          value={testPlantId}
          onChange={(e) => setTestPlantId(e.target.value)}
          className="px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div className="space-y-4 mb-8">
        <button
          onClick={() => testEndpoint(`${API_URL}/plants`, 'Get All Plants')}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
        >
          Test Get Plants
        </button>

        <button
          onClick={() => testEndpoint(`${API_URL}/plants/${testPlantId}/devices`, 'Get Devices')}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
        >
          Test Get Devices
        </button>

        <div>
          <h3 className="text-lg font-medium mb-2">Test Measurements</h3>
          {['recent', 'day', 'month', 'year'].map(type => (
            <button
              key={type}
              onClick={() => testEndpoint(
                `${API_URL}/plants/${testPlantId}/measurements`,
                `Get ${type.charAt(0).toUpperCase() + type.slice(1)} Measurements`
              )}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
            >
              Test {type.charAt(0).toUpperCase() + type.slice(1)} Measurements
            </button>
          ))}
        </div>

        <div>
          <h3 className="text-lg font-medium mb-2">Test Benchmarks</h3>
          <button
            onClick={() => testBenchmarks()}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
          >
            Test Benchmarks
          </button>
        </div>

        <div>
          <h3 className="text-lg font-medium mb-2">Test 30-Day Performance</h3>
          <div className="space-x-2">
            <button
              onClick={() => test30DayPerformance(false)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
            >
              Test All Plants Performance
            </button>
            <button
              onClick={() => test30DayPerformance(true)}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Test Single Plant Performance
            </button>
          </div>
        </div>
      </div>

      {loading && (
        <div className="text-blue-500 mb-4">Testing {currentTest}...</div>
      )}

      {error && (
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-red-500 mb-2">Error Testing {currentTest}</h2>
          <div className="text-sm mb-2">Endpoint: {error.endpoint}</div>
          <pre className="bg-red-50 p-4 rounded overflow-auto max-h-96 text-red-700">
            {JSON.stringify(error, null, 2)}
          </pre>
        </div>
      )}

      {response && (
        <div>
          <h2 className="text-xl font-semibold mb-2">Response from {currentTest}</h2>
          {currentTest.includes('30-Day Performance') && (
            <div className="bg-blue-50 p-4 rounded-lg mb-4">
              <h3 className="font-medium mb-2">30-Day Summary:</h3>
              {(() => {
                const totals = calculate30DayTotals(response);
                if (!totals) return null;
                return (
                  <div className="space-y-1">
                    <p>Plant Name: {totals.plantName || 'N/A'}</p>
                    <p>DC Size: {totals.dcSize || 'N/A'} kW</p>
                    <p>Total Generated: {totals.actualTotal.toFixed(2)} kWh</p>
                    <p>Expected Total: {totals.expectedTotal.toFixed(2)} kWh</p>
                    <p>Performance Ratio: {(totals.performanceRatio * 100).toFixed(1)}%</p>
                  </div>
                );
              })()}
            </div>
          )}
          <pre className="bg-gray-100 p-4 rounded overflow-auto max-h-96">
            {JSON.stringify(response, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
} 