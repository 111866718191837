import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, createTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import PlantDashboard from './pages/PlantDashboard';
import PlantDetails from './pages/PlantDetails';
import Devices from './pages/Devices';
import Benchmarks from './pages/Benchmarks';
import Alerts from './pages/Alerts';
import { Layout } from './components/Layout';
import { DebugView } from './pages/DebugView';
import DebugApi from './pages/DebugApi';
import { Sun } from 'lucide-react';
import kwLogo from './assets/KW TOOLBOX_logo v1.png';
import PrivacyPolicy from './pages/PrivacyPolicy';
import EULA from './pages/EULA';
import AdminPanel from './pages/AdminPanel';
import TravelPage from './pages/travel';
import { ReportView } from './pages/ReportView';
import { WeeklyReportView } from './pages/WeeklyReportView';

// Configure Amplify
Amplify.configure(awsExports);

export default function App() {
  // Define custom theme
  const theme = createTheme({
    name: 'kw-theme'
  });

  return (
    <>
      <style>{`
        div[data-amplify-authenticator][data-variation="modal"] {
          background-color: #FFFEF7;
        }

        /* Remove borders from router and tabs */
        div[data-amplify-router] {
          border: none !important;
        }

        .amplify-tabs {
          border: none !important;
        }

        .amplify-tabs__list {
          border-bottom: none !important;
        }

        /* Add stronger shadow to header */
        div[data-amplify-container] > div:first-child {
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        }

        /* Style buttons with gradient */
        .amplify-button--primary {
          background: linear-gradient(to bottom right, #B8860B, #966F00) !important;
          border: none !important;
          transition: all 0.2s ease !important;
        }

        .amplify-button--primary:hover {
          background: linear-gradient(to bottom right, #DAA520, #B8860B) !important;
          transform: translateY(-1px) !important;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
        }

        /* Style the tabs */
        .amplify-tabs__item {
          color: #6B7280 !important;
          border-top: 2px solid transparent !important;
          border-bottom: none !important;
          margin-bottom: -2px !important;
        }

        .amplify-tabs__item--active {
          color: #B8860B !important;
          border-top: 2px solid #B8860B !important;
        }

        .amplify-tabs__item:hover {
          color: #B8860B !important;
          border-top: 2px solid #B8860B !important;
        }

        /* Style links consistently */
        .amplify-button--link {
          color: #B8860B !important;
        }

        .amplify-button--link:hover {
          text-decoration: underline !important;
        }

        /* Style checkboxes */
        input[type="checkbox"] {
          accent-color: #B8860B !important;
          width: 1.2rem !important;
          height: 1.2rem !important;
        }

        input[type="checkbox"]:focus {
          outline: 2px solid rgba(184, 134, 11, 0.2) !important;
          outline-offset: 2px !important;
        }

        /* Style checkbox links */
        .checkbox-link {
          color: #B8860B !important;
          text-decoration: none !important;
          transition: all 0.2s ease !important;
        }

        .checkbox-link:hover {
          text-decoration: underline !important;
        }

        /* Style the tab underlines */
        .amplify-tabs__item::before {
          background-color: #B8860B !important;
        }

        .amplify-tabs__item[aria-selected="true"]::before {
          background-color: #B8860B !important;
        }

        /* Remove default blue focus outline */
        .amplify-tabs__item:focus {
          box-shadow: none !important;
          outline: none !important;
        }

        .amplify-tabs__item:focus::before {
          background-color: #B8860B !important;
        }

        /* Style the tab underlines and active states */
        .amplify-tabs__item {
          border-bottom-color: transparent !important;
        }

        .amplify-tabs__item--active {
          border-bottom-color: #B8860B !important;
          border-bottom-width: 2px !important;
        }

        /* Remove default focus styles */
        .amplify-tabs__item:focus-visible {
          outline: none !important;
          box-shadow: none !important;
          border-bottom-color: #B8860B !important;
        }

        /* Style hover state */
        .amplify-tabs__item:hover {
          border-bottom-color: #B8860B !important;
        }

        /* Override the tab styles completely */
        .amplify-tabs__list {
          border-bottom: none !important;
        }

        .amplify-tabs__item {
          color: #6B7280 !important;
          border-top: 2px solid transparent !important;
          border-bottom: none !important;
          margin-bottom: -2px !important;
        }

        .amplify-tabs__item--active {
          color: #B8860B !important;
          border-top: 2px solid #B8860B !important;
        }

        .amplify-tabs__item:hover {
          color: #B8860B !important;
          border-top: 2px solid #B8860B !important;
        }

        /* Remove any default Amplify UI styles */
        .amplify-tabs__item::before,
        .amplify-tabs__item::after {
          display: none !important;
        }
      `}</style>
      
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/end-user-agreement" element={<EULA />} />
          <Route path="/travel" element={<TravelPage />} />

          {/* Protected routes */}
          <Route
            path="/*"
            element={
              <Authenticator
                theme={theme}
                components={{
                  Header: () => (
                    <div className="flex flex-col items-center p-8 bg-gray-100">
                      <img 
                        src={kwLogo}
                        alt="KW Toolbox" 
                        className="h-16"
                      />
                    </div>
                  ),
                  SignIn: {
                    Header: () => (
                      <div className="text-center py-6">
                        <h2 className="text-xl font-semibold text-gray-700">Welcome Back</h2>
                        <p className="text-sm text-gray-500 mt-1">Sign in to KW Toolbox</p>
                      </div>
                    )
                  },
                  SignUp: {
                    FormFields: () => (
                      <>
                        <Authenticator.SignUp.FormFields />
                        <div className="amplify-flex" style={{ flexDirection: 'column', gap: '1rem', marginTop: '1rem' }}>
                          <div className="flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="privacy"
                                name="privacy"
                                type="checkbox"
                                required
                                className="w-5 h-5 border border-gray-300 rounded bg-gray-50"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="privacy" className="text-gray-600">
                                I agree to the{' '}
                                <a 
                                  href="/privacy-policy" 
                                  className="checkbox-link" 
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open('/privacy-policy', '_blank');
                                  }}
                                >
                                  Privacy Policy
                                </a>
                              </label>
                            </div>
                          </div>

                          <div className="flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="eula"
                                name="eula"
                                type="checkbox"
                                required
                                className="w-5 h-5 border border-gray-300 rounded bg-gray-50"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label htmlFor="eula" className="text-gray-600">
                                I accept the{' '}
                                <a 
                                  href="/end-user-agreement" 
                                  className="checkbox-link" 
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open('/end-user-agreement', '_blank');
                                  }}
                                >
                                  End User License Agreement
                                </a>
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                }}
                variation="modal"
                loginMechanisms={['email']}
              >
                {({ signOut, user }) => (
                  <Layout onSignOut={signOut} user={user}>
                    <Routes>
                      <Route path="/" element={<PlantDashboard user={user} />} />
                      <Route path="/plant/:plantId" element={<PlantDetails user={user} />} />
                      <Route path="/admin" element={<AdminPanel />} />
                      <Route path="/devices" element={<Devices user={user} />} />
                      <Route path="/alerts" element={<Alerts />} />
                      <Route path="/benchmarks" element={<Benchmarks />} />
                      <Route path="/debug/:plantId" element={<DebugView />} />
                      <Route path="/report" element={<ReportView user={user} />} />
                      <Route path="/plants/:plantId/weekly-report" element={<WeeklyReportView />} />
                    </Routes>
                  </Layout>
                )}
              </Authenticator>
            }
          />
        </Routes>
      </Router>
    </>
  );
}