import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import axios from 'axios';
import kwLogo from '../../assets/KW TOOLBOX_logo v1.png';
import 'leaflet/dist/leaflet.css';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { DownloadMultiPlantReport } from '../reports/DownloadMultiPlantReport';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Create styles for PDF
const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  // ... add more styles as needed
});

// Create PDF Document component
const WeeklyReportPDF = ({ plant, weather }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.title}>
        <Text>Weekly Plant Report</Text>
      </View>
      <View style={styles.section}>
        <Text>Plant Name: {plant.name}</Text>
        <Text>DC Size: {plant.dcSize} kW</Text>
        <Text>AC Size: {plant.acSize} kW</Text>
        {/* Add more plant details */}
      </View>
    </Page>
  </Document>
);

export function WeeklyPlantReport({ plant = {}, user }) {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const componentRef = useRef();

  // Fetch weather data including 7-day forecast
  useEffect(() => {
    const fetchWeather = async () => {
      if (!plant?.latitude || !plant?.longitude) return;

      try {
        const response = await axios.get(
          `https://api.open-meteo.com/v1/forecast?` + 
          `latitude=${plant.latitude}&` +
          `longitude=${plant.longitude}&` +
          `daily=temperature_2m_max&` +
          `temperature_unit=fahrenheit&` +
          `timezone=${encodeURIComponent(plant.timezone || 'UTC')}&` +
          `forecast_days=7`
        );

        setWeather(response.data);
      } catch (error) {
        console.error('Error fetching weather:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWeather();
  }, [plant]);

  const handleDownloadPDF = async () => {
    try {
      // Wait for any charts/maps to finish rendering
      await new Promise(resolve => setTimeout(resolve, 1000));

      const report = document.getElementById('weekly-report');
      
      // First convert the report to canvas
      const canvas = await html2canvas(report, {
        scale: 2, // Higher quality
        useCORS: true, // Handle images from other domains
        allowTaint: true,
        onclone: (clonedDoc) => {
          // Any cleanup needed for the cloned document
          const clonedReport = clonedDoc.getElementById('weekly-report');
          clonedReport.style.padding = '20px';
          // Make sure all elements are visible in clone
          Array.from(clonedReport.getElementsByTagName('*')).forEach(el => {
            el.style.display = 'block';
          });
        }
      });

      // Calculate dimensions
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      // Create PDF
      const pdf = new jsPDF('p', 'mm');
      let position = 0;

      // Add image to first page
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // Add new pages if needed
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // Save the PDF
      pdf.save(`${plant.name}-weekly-report-${new Date().toISOString().split('T')[0]}.pdf`);

    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div className="p-8 max-w-5xl mx-auto">
      {/* Download Button */}
      <div className="flex justify-end mb-4">
        <DownloadMultiPlantReport plants={[plant]} />
        <button
          onClick={handleDownloadPDF}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center gap-2"
        >
          <span>Download Report</span>
        </button>
      </div>

      {/* Report Content - Add id for PDF generation */}
      <div ref={componentRef} id="weekly-report" className="bg-white shadow-lg rounded-lg">
        {/* Header */}
        <div className="border-b pb-6 mb-6">
          <div className="text-red-500 text-xl font-semibold mb-4">Individual Site Report: Weekly</div>
          <div className="flex justify-center mb-6">
            <img src={kwLogo} alt="KW Toolbox" className="h-12" />
          </div>
        </div>

        {/* Plant Details Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <div>
            <h2 className="text-xl font-semibold mb-4">Plant Details</h2>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span className="text-gray-600">Plant Name:</span>
                <span className="font-medium">{plant?.name || 'N/A'}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">DC Size:</span>
                <span className="font-medium">{plant?.dcSize ? `${plant.dcSize} kW` : 'N/A'}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">AC Size:</span>
                <span className="font-medium">{plant?.acSize ? `${plant.acSize} kW` : 'N/A'}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Communication Status:</span>
                <span className={`font-medium ${
                  plant?.status === 'Ok' ? 'text-green-600' : 'text-red-600'
                }`}>
                  {plant?.status || 'N/A'}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Location:</span>
                <span className="font-medium">
                  {plant?.latitude && plant?.longitude 
                    ? `${plant.latitude}, ${plant.longitude}`
                    : 'N/A'}
                </span>
              </div>
            </div>

            {/* Weather Section */}
            {weather && (
              <div className="mt-6 bg-gray-50 rounded-lg p-4">
                <h3 className="font-semibold text-gray-800 mb-4">7-Day Weather Recap</h3>
                <div className="grid grid-cols-7 gap-2">
                  {weather.daily.temperature_2m_max.map((temp, index) => (
                    <div key={index} className="text-center">
                      <div className="text-sm text-gray-600">
                        {new Date(weather.daily.time[index]).toLocaleDateString('en-US', { weekday: 'short' })}
                      </div>
                      <div className="font-bold">{Math.round(temp)}°F</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Map */}
          <div className="h-[400px] rounded-lg overflow-hidden map-container">
            {plant.latitude && plant.longitude && (
              <MapContainer
                center={[plant.latitude, plant.longitude]}
                zoom={13}
                style={{ height: "100%", width: "100%" }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={[plant.latitude, plant.longitude]}>
                  <Popup>{plant.name}</Popup>
                </Marker>
              </MapContainer>
            )}
          </div>
        </div>

        {/* Generation Statistics */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Generation Statistics</h2>
          <div className="grid grid-cols-3 gap-8">
            {/* This Week */}
            <div className="bg-white border rounded-lg p-4">
              <h3 className="font-medium text-gray-800 mb-2">This Week</h3>
              <div className="text-2xl font-bold">
                {plant.measurements?.week?.total?.toLocaleString()} kWh
              </div>
            </div>

            {/* This Month */}
            <div className="bg-white border rounded-lg p-4">
              <h3 className="font-medium text-gray-800 mb-2">This Month</h3>
              <div className="text-2xl font-bold">
                {plant.measurements?.month?.total?.toLocaleString()} kWh
              </div>
            </div>

            {/* This Year */}
            <div className="bg-white border rounded-lg p-4">
              <h3 className="font-medium text-gray-800 mb-2">This Year</h3>
              <div className="text-2xl font-bold">
                {plant.measurements?.year?.total?.toLocaleString()} kWh
              </div>
            </div>
          </div>
        </div>

        {/* Recent Alerts */}
        <div className="mt-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Recent Alerts</h2>
            <button className="text-orange-500 hover:text-orange-600">See All</button>
          </div>
          {/* Add alerts list here */}
        </div>

        {/* Report Footer */}
        <div className="mt-8 text-center text-sm text-gray-500">
          Report generated: {new Date().toLocaleDateString()}
        </div>
      </div>
    </div>
  );
} 