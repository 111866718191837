import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, 
  LogOut,
  ChevronLeft,
  ChevronRight,
  Sun,
  Menu,
  X,
  Users
} from 'lucide-react';
import kwLogo from '../assets/KW TOOLBOX_logo v1.png';
import { isAdmin } from '../utils/accessControl';

export function Layout({ children, onSignOut, user }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkAdminStatus = async () => {
      const adminStatus = await isAdmin();
      setIsUserAdmin(adminStatus);
    };
    
    if (user?.signInDetails?.loginId) {
      checkAdminStatus();
    }
  }, [user]);

  console.log('Current user:', user);
  console.log('User email:', user?.signInDetails?.loginId);
  console.log('Is admin?:', isUserAdmin);

  const handleSignOut = (e) => {
    e.preventDefault();
    if (onSignOut) {
      onSignOut();
    }
  };

  const isActivePath = (path) => location.pathname === path;

  return (
    <div className="min-h-screen flex">
      {/* Desktop Sidebar */}
      <div className={`bg-white border-r border-gray-200 transition-all duration-300 relative hidden md:block ${
        isCollapsed ? 'w-16' : 'w-64'
      }`}>
        <div className="h-24 flex items-center justify-center px-4 border-b border-gray-200">
          {!isCollapsed ? (
            <Link to="/">
              <img 
                src={kwLogo} 
                alt="KW Toolbox" 
                className="h-24 w-auto py-2 cursor-pointer"
              />
            </Link>
          ) : (
            <Link to="/">
              <Sun className="h-6 w-6 cursor-pointer" />
            </Link>
          )}
        </div>

        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="absolute -right-3 top-24 -translate-y-1/2 p-1 bg-white rounded-full shadow-md border border-gray-200 hover:bg-yellow-50 hover:text-[#B8860B] transition-colors"
        >
          {isCollapsed ? (
            <ChevronRight className="h-4 w-4" />
          ) : (
            <ChevronLeft className="h-4 w-4" />
          )}
        </button>

        <div className="p-4">
          <nav className="space-y-2">
            <Link
              to="/"
              className={`flex items-center gap-3 px-3 py-2 rounded-lg transition-colors ${
                isActivePath('/') 
                  ? 'bg-yellow-50 text-[#B8860B]'
                  : 'text-gray-600 hover:bg-yellow-50 hover:text-[#B8860B]'
              }`}
            >
              <LayoutDashboard className="h-5 w-5 shrink-0" />
              {!isCollapsed && <span>Dashboard</span>}
            </Link>

            {/* <Link
              to="/benchmarks"
              className={`flex items-center gap-3 px-3 py-2 rounded-lg transition-colors ${
                isActivePath('/benchmarks') 
                  ? 'bg-yellow-50 text-[#B8860B]'
                  : 'text-gray-600 hover:bg-yellow-50 hover:text-[#B8860B]'
              }`}
            >
              <LineChart className="h-5 w-5 shrink-0" />
              {!isCollapsed && <span>Benchmarks</span>}
            </Link> */}

            {isUserAdmin && (
              <Link
                to="/admin"
                className={`flex items-center gap-3 px-3 py-2 rounded-lg transition-colors ${
                  isActivePath('/admin') 
                    ? 'bg-yellow-50 text-[#B8860B]'
                    : 'text-gray-600 hover:bg-yellow-50 hover:text-[#B8860B]'
                }`}
              >
                <Users className="h-5 w-5 shrink-0" />
                {!isCollapsed && <span>User Management</span>}
              </Link>
            )}
          </nav>
        </div>

        <div className="absolute bottom-16 left-4 right-4">
          <div className={`flex items-center gap-2 px-3 py-2 text-sm text-gray-500 ${
            isCollapsed ? 'justify-center' : ''
          }`}>
            {!isCollapsed && (
              <>
                <span>Signed in as:</span>
                <span className="font-medium truncate">
                  {user?.signInDetails?.loginId || 'Unknown User'}
                </span>
              </>
            )}
          </div>
        </div>

        <div className="absolute bottom-4 left-4 right-4">
          <button
            onClick={handleSignOut}
            className="flex items-center gap-3 px-3 py-2 w-full rounded-lg text-gray-600 hover:bg-yellow-50 hover:text-[#B8860B] transition-colors"
          >
            <LogOut className="h-5 w-5 shrink-0" />
            {!isCollapsed && <span>Sign Out</span>}
          </button>
        </div>
      </div>

      {/* Mobile Header */}
      <div className="md:hidden fixed top-0 left-0 right-0 h-16 bg-white border-b border-gray-200 flex items-center px-4 z-30">
        <button
          onClick={() => setIsMobileMenuOpen(true)}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          <Menu className="h-6 w-6" />
        </button>
        <Link to="/">
          <img src={kwLogo} alt="KW Toolbox" className="h-12 mx-auto cursor-pointer" />
        </Link>
      </div>

      {/* Mobile Sidebar */}
      {isMobileMenuOpen && (
        <>
          <div 
            className="fixed inset-0 bg-gray-600 bg-opacity-50 z-40 md:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          />
          <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-xl z-50 md:hidden">
            <div className="h-16 flex items-center justify-between px-4 border-b border-gray-200">
              <Link to="/">
                <img src={kwLogo} alt="KW Toolbox" className="h-12 cursor-pointer" />
              </Link>
              <button
                onClick={() => setIsMobileMenuOpen(false)}
                className="p-2 hover:bg-gray-100 rounded-lg"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            <nav className="p-4">
              <Link
                to="/"
                className={`flex items-center gap-3 px-3 py-2 rounded-lg transition-colors ${
                  isActivePath('/') 
                    ? 'bg-yellow-50 text-[#B8860B]'
                    : 'text-gray-600 hover:bg-yellow-50 hover:text-[#B8860B]'
                }`}
              >
                <LayoutDashboard className="h-5 w-5 shrink-0" />
                <span>Dashboard</span>
              </Link>

              {/* <Link
                to="/benchmarks"
                className={`flex items-center gap-3 px-3 py-2 rounded-lg transition-colors ${
                  isActivePath('/benchmarks') 
                    ? 'bg-yellow-50 text-[#B8860B]'
                    : 'text-gray-600 hover:bg-yellow-50 hover:text-[#B8860B]'
                }`}
              >
                <LineChart className="h-5 w-5 shrink-0" />
                <span>Benchmarks</span>
              </Link> */}

              {isUserAdmin && (
                <Link
                  to="/admin"
                  className={`flex items-center gap-3 px-3 py-2 rounded-lg transition-colors ${
                    isActivePath('/admin') 
                      ? 'bg-yellow-50 text-[#B8860B]'
                      : 'text-gray-600 hover:bg-yellow-50 hover:text-[#B8860B]'
                  }`}
                >
                  <Users className="h-5 w-5 shrink-0" />
                  <span>User Management</span>
                </Link>
              )}
            </nav>

            <div className="absolute bottom-16 left-4 right-4">
              <div className="flex items-center gap-2 px-3 py-2 text-sm text-gray-500">
                <span>Signed in as:</span>
                <span className="font-medium truncate">
                  {user?.signInDetails?.loginId || 'Unknown User'}
                </span>
              </div>
            </div>

            <div className="absolute bottom-4 left-4 right-4">
              <button
                onClick={handleSignOut}
                className="flex items-center gap-3 px-3 py-2 w-full rounded-lg text-gray-600 hover:bg-yellow-50 hover:text-[#B8860B] transition-colors"
              >
                <LogOut className="h-5 w-5" />
                <span>Sign Out</span>
              </button>
            </div>
          </div>
        </>
      )}

      {/* Main Content */}
      <div className="flex-1 bg-gray-50 md:ml-0">
        <div className="md:hidden h-16" /> {/* Spacer for mobile header */}
        {children}
      </div>
    </div>
  );
}