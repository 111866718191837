import React from 'react';

export function PlantIcon({ className }) {
  return (
    <svg 
      viewBox="0 0 500 500" 
      className={className}
      fill="currentColor"
    >
      <path d="M178.4,339.3c3.6-22.2,7.1-43.8,10.6-65.4h121.2c3.5,21.6,7,43.1,10.6,65.2h-61.7v37.5c4,0,7.7,0,11.5,0,5.4,0,10.8-.1,16.2.1,4.7.2,7.9,3.7,8.2,8.3.3,4.7-2.8,8.7-7.4,9.6-.8.2-1.7.2-2.5.2h-70.9c-.6,0-1.1,0-1.7,0-4.9-.5-8.4-4.3-8.3-9.1,0-4.9,3.4-8.7,8.5-8.8,7.5-.2,15.1-.1,22.6-.1h4.8v-37.3h-61.8,0Z" />
      <path d="M170.5,274c-3.6,21.9-7.1,43.3-10.6,65.2H61c-10.1,0-13.6-4.7-11-14.4,4.2-15.6,8.5-31.2,12.6-46.8.7-2.6,1.3-4.4,4.9-4.4,33.1.2,66.3.1,99.4.1,1,0,1.9.1,3.7.3h0Z" />
      <path d="M339.3,339.2c-3.5-21.8-7.1-43.3-10.7-65.5h4.7c32.6,0,65.1,0,97.7-.1,3.6,0,4.8,1.2,5.7,4.5,4,15.6,8.3,31.2,12.6,46.9,2.6,9.5-1,14.3-10.7,14.3-31.7,0-63.4,0-95.1,0h-4.1Z" />
      <path d="M410.2,181.1h-4.3c-29.4,0-58.8,0-88.2,0-3.3,0-4.6-.8-5.1-4.3-2.8-18.9-6-37.8-9-56.7-.2-1.4-.2-2.8-.4-4.5h81.2c7.1,0,9.7,2,11.5,8.8,4.7,18,9.4,35.9,14,53.9.2.7.2,1.4.4,2.8h0Z" />
      <path d="M88.8,180.7c2.4-9.3,4.7-18.3,7-27.3,2.6-10,5.1-20.1,7.8-30.1,1.4-5.3,4.4-7.7,9.8-7.7,27,0,54,0,81,0,.4,0,.8.1,1.8.3-1.3,8.5-2.6,17-3.9,25.5-1.9,12.1-4,24.1-5.8,36.1-.4,2.6-1.2,3.6-4,3.6-30.3,0-60.5,0-90.8,0-.8,0-1.6-.2-2.9-.4h0Z" />
      <path d="M201.1,199.4h96.9c3,18.6,6,37.1,9.1,55.8h-115c3-18.6,6-37.1,9.1-55.9h0Z" />
      <path d="M68.7,255.4c4.1-15.4,8.2-30.4,12.3-45.5.9-3.4.9-8.3,3.1-9.8,2.7-1.8,7.3-.8,11.2-.8,27.5,0,54.9,0,82.3,0h5.1c-3.1,19-6.1,37.4-9.1,56.1h-104.8Z" />
      <path d="M430,255.4h-104.4c-3-18.5-6-37-9.1-55.8,1.4-.1,2.4-.3,3.5-.3,30.6,0,61.1,0,91.7,0,2.2,0,3.9-.1,4.6,2.9,4.3,17.1,8.9,34,13.4,51,.2.7.2,1.4.3,2.3h0Z" />
      <path d="M214.8,115.8h69.6c3.5,21.7,7,43.2,10.6,65.1h-90.8c3.5-21.8,7.1-43.3,10.6-65.1Z" />
    </svg>
  );
} 