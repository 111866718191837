import React from 'react';

export function DeviceIcon({ className }) {
  return (
    <svg 
      viewBox="0 0 500 500" 
      className={className}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="10"
    >
      <path d="M107.9,385.7c6.6,5.2,12.9,7,19.4,8.1,3.5.6,7.2.9,10.8.9,70.6,0,141.2.3,211.9-.1,20.5-.1,36-10,44.9-28.9,3.4-7.2,4.7-15,4.7-23,0-71.2,0-142.4,0-213.6,0-2.2.2-4.5,1-6.5,1.4-3.6,5.6-5.7,9.1-5.1,4.1.8,7.1,4.2,7.3,8.4,0,1,0,1.9,0,2.9v214.4c0,34.8-24.3,63-58.8,68.1-2.6.4-5.2.7-7.8.7-71.2,0-142.4,0-213.5,0-12.5,0-24.6-2.4-35.2-9.4-4.4-2.9-8.2-7-11.7-11.1-3.4-4.1-2.7-8.6,1.1-12.3,94.7-94.7,189.4-189.3,284.1-284,1.2-1.2,2.8-2.2,4.5-3.5-8.2-6-16.5-7.8-25.1-8.6-2.3-.2-4.7-.2-7.1-.2-69.2,0-138.5,0-207.7,0-21.4,0-37.6,9.3-47.1,29-3.6,7.3-4.8,15.3-4.7,23.4,0,19,0,38,0,57,0,51.8,0,103.5,0,155.3,0,1.9,0,3.9-.4,5.8-.9,4.1-4.5,6.7-8.5,6.6-4.1-.1-7.7-3.3-8.3-7.5-.2-1.1,0-2.2,0-3.3,0-71.5,0-142.9,0-214.4,0-34,22.5-61.3,55.8-68,4.3-.9,8.8-1.2,13.2-1.2,69.2,0,138.5,0,207.7,0,12.5,0,24.8,1.5,35.7,7.9,5.2,3.1,9.9,7.5,14.1,12,4,4.3,3.4,8.7-.8,12.9-13.7,13.8-27.5,27.5-41.2,41.2-80.8,80.9-161.7,161.7-242.5,242.5-1.2,1.2-2.9,2-4.8,3.3h0Z" />
      <path d="M339.2,360.3c-6.5,0-11.2-2-14.5-5.9-3.7-4.4-6.7-9.4-9.8-14.3-2-3.2-3.3-6.7-5.3-9.9-3.7-5.8-5.4-5.8-9.5-.1-2.1,2.9-3.8,6.1-5.8,9.1-2.4,3.5-6.4,4.7-10.2,3.2-3.9-1.5-5.9-5.1-5.3-9.3,1.2-9.2,13.5-22.2,22.6-24.1,7.9-1.6,14.4,1,19.2,7,3.6,4.5,6.1,9.8,9.2,14.8,2.2,3.6,4.5,7.3,6.9,10.8,1.3,1.9,3.1,1.9,4.4,0,2.5-3.3,5-6.7,7.1-10.2,3.3-5.3,7.8-7.2,12.4-4.7,4.4,2.4,6,7.4,3,12.6-3.1,5.3-6.8,10.4-10.9,15-3.7,4.2-9,5.8-13.4,6h0Z" />
      <path d="M165.9,135.1c-11.1,0-22.2,0-33.3,0-6.1,0-10.1-3.5-10-8.6,0-5.2,3.9-8.7,10.1-8.7,22.2,0,44.3,0,66.5,0,6.2,0,10,3.5,9.9,8.8,0,5.4-3.7,8.6-10,8.6-11.1,0-22.2,0-33.3,0h0Z" />
      <path d="M135.9,152.4c1.5,0,3.1-.1,4.6,0,4.7.4,8.1,3.8,8.3,8.3.2,4.4-3,8.4-7.6,8.9-3.6.4-7.2.4-10.8,0-4.7-.5-7.8-4.4-7.7-8.9.2-4.5,3.6-8,8.2-8.3,1.6-.1,3.3,0,5,0h0Z" />
      <path d="M196.3,152.4c1.7,0,3.3-.1,5,0,4.5.4,7.7,3.7,7.9,8.1.2,4.5-2.5,8.3-7,8.9-3.8.5-7.8.5-11.6,0-4.6-.5-7.7-4.7-7.4-9.1.3-4.4,3.8-7.8,8.5-8.1,1.5-.1,3,0,4.6,0h0s0,0,0,0Z" />
      <path d="M165.9,152.4c1.5,0,3.1,0,4.6,0,4.7.3,8.2,3.6,8.5,8.1.3,4.6-2.9,8.7-7.7,9.2-3.4.3-6.9.3-10.4,0-4.9-.4-8-4.3-7.8-9.1.2-4.5,3.6-7.9,8.3-8.2,1.5-.1,3,0,4.6,0h0Z" />
    </svg>
  );
} 