import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import axios from 'axios';
import { WeeklyPlantReport } from '../components/reports/WeeklyPlantReport';
import { getAuthorizedPlants } from '../utils/accessControl';

export function WeeklyReportView() {
  const { plantId } = useParams();
  const { user } = useAuthenticator();
  const [plant, setPlant] = useState(null);
  const [loading, setLoading] = useState(true);

  const API_URL = 'https://49bgmmmqnk.execute-api.us-east-1.amazonaws.com/prod';
  const headers = { 'Accept': 'application/json' };

  useEffect(() => {
    const fetchPlantData = async () => {
      try {
        // First get authorized plants to verify access
        const authorizedPlants = await getAuthorizedPlants();
        if (!authorizedPlants.includes(plantId)) {
          console.error('Not authorized to view this plant');
          setLoading(false);
          return;
        }

        // Get all plants
        const response = await axios.get(`${API_URL}/plants`, { headers });
        const plantsData = typeof response.data === 'string' 
          ? JSON.parse(response.data) 
          : response.data;

        // Find the specific plant
        const plantData = plantsData.find(p => p.plantId === plantId);
        if (!plantData) {
          console.error('Plant not found');
          setLoading(false);
          return;
        }

        // Get performance data
        const performanceResponse = await axios.get(
          `https://n395eo29z2.execute-api.us-east-1.amazonaws.com/prod/plants/30day/${plantId}`,
          { headers }
        );
        const performanceData = performanceResponse.data;

        // Get measurements data
        const measurementsResponse = await axios.get(
          `${API_URL}/plants/${plantId}/measurements`,
          { headers }
        );
        const measurements = measurementsResponse.data;

        // Determine status based on original status and recent generation
        let effectiveStatus = plantData.status;
        if (effectiveStatus === 'Unknown') {
          effectiveStatus = hasRecentGeneration(performanceData.dailyAverageActual) 
            ? 'Ok' 
            : 'Warning';
        }

        setPlant({
          ...plantData,
          status: effectiveStatus,
          performanceRatio: performanceData.performanceRatio,
          hasBenchmarkData: performanceData.hasBenchmarkData !== false,
          dailyAverageActual: performanceData.dailyAverageActual,
          measurements: {
            day: measurements.day,
            month: measurements.month,
            year: measurements.year,
            recent: measurements.recent
          }
        });
      } catch (error) {
        console.error('Error fetching plant data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (plantId) {
      fetchPlantData();
    }
  }, [plantId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!plant) {
    return <div>No plant data found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <WeeklyPlantReport plant={plant} user={user} />
    </div>
  );
}

function hasRecentGeneration(dailyAverage) {
  return dailyAverage && dailyAverage > 0;
} 