import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { CircleDot, Sun, Cloud, MapPin, Circle, AlertTriangle, XCircle } from 'lucide-react';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { PowerGauge } from './PowerGauge';

// Fix for the default marker icon
const defaultIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = defaultIcon;

function getAlertLevel(plantData) {
  // Default to 0 (good standing)
  let alertLevel = 0;

  // Check for communication issues (level 1)
  const lastUpdate = new Date(plantData.lastUpdated);
  const now = new Date();
  const hoursSinceLastUpdate = (now - lastUpdate) / (1000 * 60 * 60);
  
  if (hoursSinceLastUpdate >= 24) {
    return 1; // Communication issue
  }

  // Check for performance issues
  if (plantData.performanceRatio) {
    const ratio = parseFloat(plantData.performanceRatio);
    
    // Severe underperformance (level 3)
    if (ratio < 80) {
      return 3;
    }
    
    // Moderate underperformance (level 2)
    if (ratio >= 80 && ratio < 93) {
      return 2;
    }
  }

  return alertLevel;
}

function getAlertColor(level) {
  switch (level) {
    case 0:
      return 'bg-green-500'; // Good
    case 1:
      return 'bg-gray-500';  // Communication issue
    case 2:
      return 'bg-yellow-500'; // Moderate alert
    case 3:
      return 'bg-red-500';   // Urgent alert
    default:
      return 'bg-gray-300';
  }
}

function getAlertDescription(level) {
  switch (level) {
    case 0:
      return 'System operating normally';
    case 1:
      return 'Communication issue detected';
    case 2:
      return 'Moderate performance issue';
    case 3:
      return 'Severe performance issue';
    default:
      return 'Status unknown';
  }
}

const getStatusIcon = (status) => {
  switch (status) {
    case 'Ok':
      return <Circle className="h-4 w-4 text-green-500 fill-current" />;
    case 'Warning':
      return <AlertTriangle className="h-4 w-4 text-yellow-500" />;
    case 'CommunicationMonitoringFault':
      return <AlertTriangle className="h-4 w-4 text-orange-500" />;
    case 'Error':
      return <XCircle className="h-4 w-4 text-red-500" />;
    case 'Unknown':
    default:
      return <AlertTriangle className="h-4 w-4 text-gray-400" />;
  }
};

const getStatusText = (status) => {
  switch (status) {
    case 'Ok':
      return 'Connected';
    case 'Warning':
      return 'Warning';
    case 'CommunicationMonitoringFault':
      return 'Disconnected';
    case 'Error':
      return 'Error';
    case 'Unknown':
    default:
      return 'Unknown';
  }
};

const getStatusTextColor = (status) => {
  switch (status) {
    case 'Ok':
      return 'text-green-700';
    case 'Warning':
      return 'text-yellow-700';
    case 'CommunicationMonitoringFault':
      return 'text-orange-700';
    case 'Error':
      return 'text-red-700';
    default:
      return 'text-gray-700';
  }
};

export function PlantInfo({ plantData, systemInfo, measurements }) {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const hasLocation = plantData?.latitude && plantData?.longitude;
  const alertLevel = getAlertLevel(plantData);

  // Add this useEffect for real-time clock
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchWeather = async () => {
      if (!hasLocation) return;

      try {
        setLoading(true);
        const response = await axios.get(
          `https://api.open-meteo.com/v1/forecast?` + 
          `latitude=${plantData.latitude}&` +
          `longitude=${plantData.longitude}&` +
          `hourly=temperature_2m&` +
          `temperature_unit=fahrenheit&` +
          `timezone=${encodeURIComponent(plantData.timezone || 'UTC')}`
        );

        const hourlyData = response.data.hourly;
        const currentHourIndex = new Date().getHours();
        const tomorrowSameHourIndex = currentHourIndex + 24;

        setWeather({
          current_temperature: hourlyData.temperature_2m[currentHourIndex],
          forecast_temperature: hourlyData.temperature_2m[tomorrowSameHourIndex],
        });
      } catch (error) {
        console.error('Error fetching weather:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWeather();
  }, [plantData, hasLocation]);

  const hasValidLocation = (plantData) => {
    return (
      plantData.latitude && 
      plantData.longitude && 
      plantData.latitude !== 0 && 
      plantData.longitude !== 0
    );
  };

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-4">Plant Details</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 h-[400px]">
        {/* Left Column: Plant Info + Weather */}
        <div className="flex flex-col bg-white rounded-lg shadow-sm h-[400px]">
          {/* Plant Info Section */}
          <div className="flex-grow p-4 space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Plant Name:</span>
              <span className="font-medium">{plantData?.name || 'N/A'}</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-gray-600">DC Size:</span>
              <span className="font-medium">{systemInfo?.systemInfo?.dcSize || 'N/A'} kW</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-gray-600">AC Size:</span>
              <span className="font-medium">{systemInfo?.systemInfo?.acSize || 'N/A'} kW</span>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-gray-600">Communication Status:</span>
              <div className="flex items-center gap-2">
                {getStatusIcon(plantData.status)}
                <span className={`text-sm ${getStatusTextColor(plantData.status)}`}>
                  {getStatusText(plantData.status)}
                </span>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <span className="text-gray-600">Local Time:</span>
              <span className="font-medium">
                {currentTime.toLocaleTimeString('en-US', { 
                  timeZone: plantData?.timezone || 'UTC' 
                })}
              </span>
            </div>

            {hasValidLocation(plantData) ? (
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Location:</span>
                <div className="flex items-center gap-2">
                  <MapPin className="h-4 w-4 text-gray-500" />
                  <span className="font-medium">
                    {plantData.latitude.toFixed(6)}, {plantData.longitude.toFixed(6)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Location:</span>
                <div className="flex items-center gap-2">
                  <MapPin className="h-4 w-4 text-gray-500" />
                  <span className="font-medium">
                    Location data not available
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* Weather Section */}
          {hasValidLocation(plantData) ? (
            <div className="bg-gray-50 rounded-lg p-4 mt-auto">
              <h3 className="text-sm font-medium text-gray-900 mb-4">WEATHER</h3>
              {loading ? (
                <div className="text-center text-gray-500">Loading weather data...</div>
              ) : weather ? (
                <div className="grid grid-cols-2 gap-4">
                  {/* Current Weather */}
                  <div className="flex items-center gap-3">
                    <Sun className="h-8 w-8 text-yellow-500" />
                    <div>
                      <div className="text-2xl font-bold">{Math.round(weather.current_temperature)}°F</div>
                      <div className="text-sm text-gray-600">currently</div>
                    </div>
                  </div>
                  
                  {/* Tomorrow's Weather */}
                  <div className="flex items-center gap-3">
                    <Cloud className="h-8 w-8 text-gray-400" />
                    <div>
                      <div className="text-2xl font-bold">{Math.round(weather.forecast_temperature)}°F</div>
                      <div className="text-sm text-gray-600">Tomorrow</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center text-gray-500">Weather data unavailable</div>
              )}
            </div>
          ) : (
            <div className="bg-gray-50 rounded-lg p-4 mt-auto">
              <h3 className="text-sm font-medium text-gray-900 mb-4">WEATHER</h3>
              <div className="text-center text-gray-500">
                <p>Location data not available</p>
                <p className="text-sm">Coordinates have not been set for this site</p>
              </div>
            </div>
          )}
        </div>

        {/* Middle Column: Map */}
        {hasValidLocation(plantData) ? (
          <div className="h-[400px] rounded-lg overflow-hidden shadow-sm">
            <MapContainer
              center={[plantData.latitude, plantData.longitude]}
              zoom={13}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={[plantData.latitude, plantData.longitude]}>
                <Popup>{plantData.name}</Popup>
              </Marker>
            </MapContainer>
          </div>
        ) : (
          <div className="h-64 rounded-lg bg-gray-100 flex items-center justify-center">
            <div className="text-center text-gray-500">
              <p>Location data not available</p>
              <p className="text-sm">Coordinates have not been set for this site</p>
            </div>
          </div>
        )}

        {/* Right Column: Current Power */}
        <div className="bg-gray-50 rounded-lg p-4 h-[400px] flex flex-col shadow-sm">
          <h3 className="text-sm font-medium text-gray-900 mb-4">CURRENT POWER</h3>
          <div className="flex-grow flex flex-col justify-center">
            <PowerGauge 
              measurements={measurements}
              maxValue={systemInfo?.systemInfo?.acSize * 1000 || 100000}
              performanceRatio={plantData.performanceRatio}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
