import React, { useEffect, useState } from 'react';
import { MultiPlantReport } from '../components/reports/MultiPlantReport';
import { useAuthenticator } from '@aws-amplify/ui-react';
import axios from 'axios';
import { getAuthorizedPlants } from '../utils/accessControl';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function ReportView() {
  const { user } = useAuthenticator();
  const [plants, setPlants] = useState(null);
  const [loading, setLoading] = useState(true);

  const API_URL = 'https://49bgmmmqnk.execute-api.us-east-1.amazonaws.com/prod';
  const headers = { 'Accept': 'application/json' };

  useEffect(() => {
    const fetchPlants = async () => {
      try {
        // First get authorized plant IDs
        const authorizedPlantIds = await getAuthorizedPlants();
        console.log('Authorized plant IDs:', authorizedPlantIds);

        // Get all plants
        const response = await axios.get(`${API_URL}/plants`, { headers });
        const plantsData = typeof response.data === 'string' 
          ? JSON.parse(response.data) 
          : response.data;

        // Filter to get only authorized plants
        const authorizedPlantsData = plantsData.filter(plant => 
          authorizedPlantIds.includes(plant.plantId)
        );
        console.log('Authorized plants data:', authorizedPlantsData);

        // Get details for each plant
        const plantsWithDetails = await Promise.all(authorizedPlantsData.map(async plant => {
          try {
            // Get performance data
            const performanceResponse = await axios.get(
              `https://n395eo29z2.execute-api.us-east-1.amazonaws.com/prod/plants/30day/${plant.plantId}`,
              { headers }
            );
            const performanceData = performanceResponse.data;

            // Get measurements data
            const measurementsResponse = await axios.get(
              `${API_URL}/plants/${plant.plantId}/measurements`,
              { headers }
            );
            const measurements = measurementsResponse.data;

            // Determine status based on original status and recent generation
            let effectiveStatus = plant.status;
            if (effectiveStatus === 'Unknown') {
              effectiveStatus = hasRecentGeneration(performanceData.dailyAverageActual) 
                ? 'Ok' 
                : 'Warning';
            }

            return {
              ...plant,
              status: effectiveStatus,
              performanceRatio: performanceData.performanceRatio,
              hasBenchmarkData: performanceData.hasBenchmarkData !== false,
              dailyAverageActual: performanceData.dailyAverageActual,
              measurements: {
                day: measurements.day,
                month: measurements.month,
                year: measurements.year,
                recent: measurements.recent
              }
            };
          } catch (error) {
            console.error(`Error fetching details for plant ${plant.plantId}:`, error);
            return plant;
          }
        }));

        console.log('Plants with details:', plantsWithDetails);
        setPlants(plantsWithDetails);
        setLoading(false);

      } catch (error) {
        console.error('Error fetching plants:', error);
        setLoading(false);
      }
    };

    if (user) {
      fetchPlants();
    }
  }, [user]);

  const handleDownloadPDF = async () => {
    try {
      // Wait for charts to render
      await new Promise(resolve => setTimeout(resolve, 1000));

      const report = document.getElementById('portfolio-report');
      
      const canvas = await html2canvas(report, {
        scale: 1.5, // Adjusted for better fit
        useCORS: true,
        logging: true,
        width: report.offsetWidth,
        height: report.offsetHeight,
        windowWidth: report.scrollWidth,
        windowHeight: report.scrollHeight
      });

      // Convert to PDF maintaining aspect ratio
      const imgData = canvas.toDataURL('image/png');

      // Calculate dimensions and margins
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      
      // Create PDF slightly larger than image to allow for margins
      const pdf = new jsPDF({
        orientation: imgWidth > imgHeight ? 'l' : 'p',
        unit: 'px',
        format: [imgWidth + 40, imgHeight + 40] // Add 20px margin on each side
      });

      // Add image with 20px margins on all sides
      pdf.addImage(imgData, 'PNG', 20, 20, imgWidth, imgHeight);
      pdf.save(`portfolio-report-${new Date().toISOString().split('T')[0]}.pdf`);

    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="p-4 flex justify-end max-w-7xl mx-auto">
        <button
          onClick={handleDownloadPDF}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center gap-2"
        >
          <span>Download Report</span>
        </button>
      </div>

      <div id="portfolio-report" className="max-w-7xl mx-auto bg-white p-8 shadow-lg rounded-lg">
        <MultiPlantReport plants={plants} user={user} />
      </div>
    </div>
  );
}

function hasRecentGeneration(dailyAverage) {
  return dailyAverage && dailyAverage > 0;
} 