import React, { useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { MultiPlantReport } from './MultiPlantReport';
import { Download } from 'lucide-react';
import axios from 'axios';

export function DownloadMultiPlantReport({ plants }) {
  const [isGenerating, setIsGenerating] = useState(false);
  const API_URL = 'https://49bgmmmqnk.execute-api.us-east-1.amazonaws.com/prod';

  const fetchPlantData = async (plantId) => {
    try {
      console.log('Starting to fetch data for plant:', plantId);

      // Get performance data
      const performanceResponse = await axios.get(
        `https://n395eo29z2.execute-api.us-east-1.amazonaws.com/prod/plants/30day/${plantId}`,
        { headers: { 'Accept': 'application/json' } }
      );

      // Get measurements one by one
      const measurements = {};
      
      // Recent measurements
      const recentResponse = await axios.get(
        `${API_URL}/plants/${plantId}/measurements`,
        {
          params: { type: 'recent' },
          headers: { 'Accept': 'application/json' }
        }
      );
      measurements.recent = recentResponse.data.measurements; // Extract from measurements key

      // Daily measurements
      const dayResponse = await axios.get(
        `${API_URL}/plants/${plantId}/measurements`,
        {
          params: { type: 'day' },
          headers: { 'Accept': 'application/json' }
        }
      );
      measurements.day = dayResponse.data.measurements; // Extract from measurements key

      // Monthly measurements
      const monthResponse = await axios.get(
        `${API_URL}/plants/${plantId}/measurements`,
        {
          params: { type: 'month' },
          headers: { 'Accept': 'application/json' }
        }
      );
      measurements.month = monthResponse.data.measurements; // Extract from measurements key

      // Yearly measurements
      const yearResponse = await axios.get(
        `${API_URL}/plants/${plantId}/measurements`,
        {
          params: { type: 'year' },
          headers: { 'Accept': 'application/json' }
        }
      );
      measurements.year = yearResponse.data.measurements; // Extract from measurements key

      const result = {
        performanceData: performanceResponse.data,
        measurements: measurements
      };

      console.log('Final data structure for plant:', result);
      return result;
    } catch (error) {
      console.error(`Error fetching data for plant ${plantId}:`, error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        config: error.config
      });
      return null;
    }
  };

  const handleDownload = async () => {
    if (!plants || !Array.isArray(plants) || plants.length === 0) {
      console.error('No plant data available for report generation');
      return;
    }

    try {
      setIsGenerating(true);
      console.log('Starting report generation with plants:', plants);

      // Fetch latest data for all plants
      const plantsWithData = await Promise.all(
        plants.map(async (plant) => {
          console.log('Processing plant:', plant.plantId);
          const data = await fetchPlantData(plant.plantId);
          if (!data) {
            console.log('No data received for plant:', plant.plantId);
            return plant;
          }

          const updatedPlant = {
            ...plant,
            performanceRatio: data.performanceData.performanceRatio,
            measurements: data.measurements,
            hasBenchmarkData: data.performanceData.hasBenchmarkData !== false,
            dailyAverageActual: data.performanceData.dailyAverageActual
          };
          console.log('Updated plant data:', updatedPlant);
          return updatedPlant;
        })
      );

      console.log('All plants processed:', plantsWithData);

      const blob = await pdf(
        <MultiPlantReport plants={plantsWithData} />
      ).toBlob();
      
      console.log('PDF generated successfully');

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `solar_plants_report_${new Date().toISOString().split('T')[0]}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <button
      onClick={handleDownload}
      disabled={isGenerating || !plants || plants.length === 0}
      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
        ${isGenerating || !plants || plants.length === 0 
          ? 'bg-gray-300 cursor-not-allowed' 
          : 'bg-blue-500 hover:bg-blue-600 text-white'}`}
    >
      <Download className="h-4 w-4" />
      <span>{isGenerating ? 'Generating...' : 'Download Report'}</span>
    </button>
  );
} 