import React, { useState, useLayoutEffect, useRef } from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';

export function TravelForm() {
  const navigate = useNavigate();
  const [location, setLocation] = useState('');
  
  // Refs for GSAP animations
  const mainRef = useRef(null);
  const stepsRef = useRef(null);
  const formRef = useRef(null);
  const progressRefs = useRef([]);
  
  const steps = [
    { id: 'location', label: 'Location', active: true },
    { id: 'interests', label: 'Interests', active: false },
    { id: 'days', label: 'Days', active: false },
    { id: 'travelers', label: 'Travelers', active: false },
  ];

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      // Initial page load animation
      const tl = gsap.timeline({
        defaults: { 
          duration: 0.8,
          ease: 'power3.out'
        }
      });

      // Animate the back button
      tl.from('.back-button', {
        y: -20,
        opacity: 0,
      });

      // Animate the progress steps
      tl.from(progressRefs.current, {
        y: -30,
        opacity: 0,
        stagger: 0.1,
      }, '-=0.5');

      // Animate the connector lines
      tl.from('.connector-line', {
        scaleX: 0,
        transformOrigin: 'left center',
      }, '-=0.8');

      // Animate the form content
      tl.from(formRef.current, {
        y: 30,
        opacity: 0,
      }, '-=0.4');

    }, mainRef);

    return () => ctx.revert();
  }, []);

  // Animation for input focus
  const handleInputFocus = () => {
    gsap.to(formRef.current, {
      scale: 1.02,
      duration: 0.3,
      ease: 'power2.out'
    });
  };

  // Animation for input blur
  const handleInputBlur = () => {
    gsap.to(formRef.current, {
      scale: 1,
      duration: 0.3,
      ease: 'power2.out'
    });
  };

  return (
    <div className="p-6" ref={mainRef}>
      <div className="max-w-7xl mx-auto">
        {/* Back Button */}
        <button
          onClick={() => navigate('/')}
          className="back-button flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
        >
          <ArrowLeft className="h-5 w-5" />
          <span>Back to Dashboard</span>
        </button>

        {/* Progress Indicator */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6" ref={stepsRef}>
          <div className="flex items-center justify-between">
            {steps.map((step, index) => (
              <React.Fragment key={step.id}>
                {/* Step Circle */}
                <div 
                  className="flex flex-col items-center"
                  ref={el => progressRefs.current[index] = el}
                >
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center
                      ${step.active ? 'bg-[#B8860B]' : 'bg-gray-200'}`}
                  >
                    <div className="w-2 h-2 rounded-full bg-white" />
                  </div>
                  <span className={`mt-2 text-sm ${step.active ? 'text-[#B8860B] font-medium' : 'text-gray-500'}`}>
                    {step.label}
                  </span>
                </div>
                
                {/* Connector Line */}
                {index < steps.length - 1 && (
                  <div className="flex-1 h-[2px] bg-gray-200 mx-2 connector-line" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* Form Content */}
        <div className="bg-white rounded-xl shadow-sm p-6" ref={formRef}>
          <h1 className="text-2xl font-semibold text-gray-900 mb-8">Where to?</h1>
          
          <div className="relative">
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              placeholder="Hawaii"
              className="w-full px-4 py-3 border border-gray-200 rounded-lg
                text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 
                focus:ring-[#B8860B] focus:border-transparent transition-all duration-300"
            />
            <div className="absolute -bottom-6 left-4 text-sm text-gray-500">*required</div>
          </div>
        </div>
      </div>
    </div>
  );
} 