import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip);

export function PowerGauge({ measurements, maxValue = 100, performanceRatio }) {
  console.log('PowerGauge measurements:', measurements);
  const currentPower = measurements?.recent?.set?.[0]?.pvGeneration || 0;
  console.log('Current power:', currentPower);
  
  // Convert values from W to kW
  const valueInKW = currentPower / 1000;
  const maxValueInKW = maxValue / 1000;
  
  // Convert value to percentage for the gauge
  const percentage = Math.min((valueInKW / maxValueInKW) * 100, 100);
  const remaining = 100 - percentage;

  // Format performance ratio as percentage
  const formattedPerformanceRatio = performanceRatio 
    ? `${Math.round(performanceRatio * 100)}%`
    : 'N/A';

  const data = {
    datasets: [{
      data: [percentage, remaining],
      backgroundColor: [
        '#FFD700', // Brand yellow for value
        'rgb(229, 231, 235)', // Light gray for remaining
      ],
      borderWidth: 0,
      circumference: 180,
      rotation: 270,
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false }
    },
    cutout: '85%'
  };

  return (
    <div className="flex flex-col h-full gap-4">
      {/* Power Gauge Card */}
      <div className="bg-white rounded-xl p-6 flex-1">
        <div className="relative">
          <div className="h-40">
            <Doughnut data={data} options={options} />
            <div className="absolute inset-0 flex flex-col items-center justify-center mt-8">
              <div className="text-3xl font-bold text-gray-900">
                {Math.round(valueInKW)}
                <span className="text-xl font-normal text-gray-500"> / {Math.round(maxValueInKW)}</span>
              </div>
              <span className="text-sm text-gray-500 mt-1">kW</span>
            </div>
          </div>
        </div>
      </div>
      
      {/* 30-Day Performance Card */}
      <div className="bg-white rounded-xl p-4">
        <div className="text-center">
          <div className="text-gray-600">30-Day Performance:</div>
          <div className="text-3xl font-bold text-gray-900">
            {formattedPerformanceRatio}
          </div>
        </div>
      </div>
    </div>
  );
}