import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowLeft, Loader2 } from 'lucide-react';
import { PlantInfo } from '../components/plant-details/PlantInfo';
import { GenerationStats, chartRefs } from '../components/plant-details/GenerationStats';
import { DevicesList } from '../components/plant-details/DevicesList';
import { DownloadButton } from '../components/plant-details/DownloadButton';
import { PlantDevices } from '../components/plant-details/PlantDevices';
import { Alerts } from '../components/plant-details/Alerts';
import KWToolboxLogo from '../assets/KW TOOLBOX_logo v1.png';
import { hasPlantAccess } from '../utils/accessControl';

function PlantDetails({ user }) {
  const { plantId } = useParams();
  const navigate = useNavigate();
  const [plantData, setPlantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [systemInfo, setSystemInfo] = useState(null);
  const [chartRefs, setChartRefs] = useState([]);

  // Define base URLs
  const API_URL = 'https://49bgmmmqnk.execute-api.us-east-1.amazonaws.com/prod';
  const BENCHMARKS_URL = 'https://zf3urt9bg3.execute-api.us-east-1.amazonaws.com/prod';

  // Define headers outside both useEffect hooks since we'll use it in both
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  // Add fetchMeasurements function here
  const fetchMeasurements = async () => {
    try {
      // Add recent measurements
      const recentResponse = await axios.get(
        `${API_URL}/plants/${plantId}/measurements?type=recent`,
        { headers }
      );

      const dayResponse = await axios.get(
        `${API_URL}/plants/${plantId}/measurements?type=day`,
        { headers }
      );

      const monthResponse = await axios.get(
        `${API_URL}/plants/${plantId}/measurements?type=month`,
        { headers }
      );

      const yearResponse = await axios.get(
        `${API_URL}/plants/${plantId}/measurements?type=year`,
        { headers }
      );

      // The data is directly in data.measurements and data.thirtyDayStats
      return {
        recent: {
          measurements: recentResponse.data.measurements,
          thirtyDayStats: recentResponse.data.thirtyDayStats
        },
        day: {
          measurements: dayResponse.data.measurements,
          thirtyDayStats: dayResponse.data.thirtyDayStats
        },
        month: {
          measurements: monthResponse.data.measurements,
          thirtyDayStats: monthResponse.data.thirtyDayStats
        },
        year: {
          measurements: yearResponse.data.measurements,
          thirtyDayStats: yearResponse.data.thirtyDayStats
        }
      };
    } catch (error) {
      console.error('Error in fetchMeasurements:', error);
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        config: error.config
      });
      throw error;
    }
  };

  useEffect(() => {
    const checkAccess = async () => {
      const hasAccess = await hasPlantAccess(plantId);
      if (!hasAccess) {
        navigate('/');
      }
    };
    
    checkAccess();
  }, [plantId, navigate]);

  useEffect(() => {
    const fetchPlantData = async () => {
      if (!plantId) return;

      try {
        setLoading(true);
        setError(null);

        // Fetch all plants to get basic info
        console.log('Fetching plants data...');
        const plantsResponse = await axios.get(
          'https://49bgmmmqnk.execute-api.us-east-1.amazonaws.com/prod/plants',
          { headers }
        );
        const plantInfo = plantsResponse.data.find(p => p.plantId === plantId);
        
        if (!plantInfo) {
          throw new Error('Plant not found');
        }

        // Fetch devices
        console.log('Fetching devices data...');
        const devicesResponse = await axios.get(
          `${API_URL}/plants/${plantId}/devices`,
          { headers }
        );
        console.log('Raw devices response:', devicesResponse);

        // The response is already JSON, no need to parse
        const devicesData = devicesResponse.data || { devices: [] };
        console.log('Devices data:', devicesData);

        // Fetch measurements
        console.log('Fetching measurements data...');
        const measurementsData = await fetchMeasurements();
        console.log('Raw measurements data:', measurementsData);
        console.log('Day measurements:', measurementsData?.day);
        console.log('Month measurements:', measurementsData?.month);
        console.log('Year measurements:', measurementsData?.year);

        // Get performance data from the 30-day stats
        const performanceData = measurementsData?.day?.thirtyDayStats || {};

        // Combine all data with safe fallbacks
        const hasRecentGeneration = (perfData) => {
          return perfData.dailyAverageActual && parseFloat(perfData.dailyAverageActual) > 0;
        };

        const combinedData = {
          ...plantInfo,
          status: plantInfo.status === 'Unknown' 
            ? hasRecentGeneration(performanceData) ? 'Ok' : 'Warning'
            : plantInfo.status,
          devices: devicesData.devices || [],
          measurements: {
            recent: measurementsData?.recent?.measurements || { set: [] },
            day: measurementsData?.day?.measurements || { set: [] },
            month: measurementsData?.month?.measurements || { set: [] },
            year: measurementsData?.year?.measurements || { set: [] }
          },
          thirtyDayStats: measurementsData?.day?.thirtyDayStats || {}
        };

        console.log('Final combined data:', combinedData);
        console.log('Final measurements structure:', combinedData.measurements);
        setPlantData(combinedData);
      } catch (error) {
        console.error('Error fetching plant data:', error);
        console.error('Error details:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        setError(error.message || 'Failed to load plant data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPlantData();

    // Cleanup function
    return () => {
      setPlantData(null);
      setError(null);
    };
  }, [plantId]);

  useEffect(() => {
    const fetchSystemInfo = async () => {
      if (!plantId) return;
      
      try {
        const systemInfoResponse = await axios.get(
          `${BENCHMARKS_URL}/benchmarks/${plantId}`,
          { headers }
        );
        
        if (systemInfoResponse.data) {
          setSystemInfo(systemInfoResponse.data);
        }
      } catch (err) {
        console.error('Error fetching system info:', err);
      }
    };

    fetchSystemInfo();
  }, [plantId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex items-center gap-2">
          <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
          <span className="text-gray-600">Loading plant details...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="max-w-7xl mx-auto">
          <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
            <p className="text-red-700">{error}</p>
          </div>
          <button
            onClick={() => navigate('/')}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="h-5 w-5" />
            <span>Back to Sites</span>
          </button>
        </div>
      </div>
    );
  }

  if (!plantData) {
    return (
      <div className="p-6">
        <div className="max-w-7xl mx-auto">
          <div className="text-center text-gray-600">
            No data available for this plant
          </div>
          <button
            onClick={() => navigate('/')}
            className="mt-4 flex items-center gap-2 text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="h-5 w-5" />
            <span>Back to Sites</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => navigate('/')}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="h-5 w-5" />
            <span>Back to Sites</span>
          </button>
          <div>
            <DownloadButton 
              plantData={plantData} 
              measurements={plantData.measurements}
              chartRefs={chartRefs}
            />
          </div>
        </div>

        <header className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Plant Details</h1>
          <img 
            src={KWToolboxLogo} 
            alt="KW Toolbox" 
            className="h-12"
          />
        </header>

        {/* Tabs */}
        <div className="mb-6 border-b border-gray-200">
          <nav className="flex space-x-8">
            <button
              onClick={() => setActiveTab('overview')}
              className={`py-4 px-1 inline-flex items-center gap-2 border-b-2 ${
                activeTab === 'overview'
                  ? 'border-[#B8860B] text-[#B8860B]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Overview
            </button>
            <button
              onClick={() => setActiveTab('devices')}
              className={`py-4 px-1 inline-flex items-center gap-2 border-b-2 ${
                activeTab === 'devices'
                  ? 'border-[#B8860B] text-[#B8860B]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Devices
            </button>
            <button
              onClick={() => setActiveTab('alerts')}
              className={`py-4 px-1 inline-flex items-center gap-2 border-b-2 ${
                activeTab === 'alerts'
                  ? 'border-[#B8860B] text-[#B8860B]'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }`}
            >
              Alerts
            </button>
          </nav>
        </div>

        {/* Tab Content */}
        {activeTab === 'overview' ? (
          <div className="grid grid-cols-1 gap-6">
            <PlantInfo 
              plantData={plantData} 
              systemInfo={systemInfo}
              measurements={plantData.measurements}
            />
            <GenerationStats 
              measurements={plantData.measurements} 
              systemInfo={systemInfo}
              plantId={plantId}
            />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="md:col-span-1">
                <DevicesList 
                  devices={plantData.devices} 
                  onSeeAll={() => setActiveTab('devices')}
                />
              </div>
              <div className="md:col-span-2">
                <Alerts 
                  plantId={plantId} 
                  onSeeAll={() => setActiveTab('alerts')}
                />
              </div>
            </div>
          </div>
        ) : activeTab === 'devices' ? (
          <PlantDevices devices={plantData.devices} plantName={plantData.name} />
        ) : (
          <Alerts plantId={plantId} />
        )}
      </div>
    </div>
  );
}

export default PlantDetails;