import React from 'react';
import kwLogo from '../assets/KW TOOLBOX_logo v1.png';

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header with logo */}
      <div className="bg-white shadow-sm">
        <div className="max-w-4xl mx-auto py-8 flex justify-center">
          <img src={kwLogo} alt="KW Toolbox" className="h-16" />
        </div>
      </div>

      {/* Content */}
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-8">Privacy Policy for KW Toolbox, LLC</h1>
        <p className="text-sm text-gray-500 mb-8">Effective Date: 1/1/2025</p>

        <div className="prose prose-lg max-w-none">
          <p className="mb-6">
            KW Toolbox, LLC ("Company," "we," "us," or "our") respects your privacy and is committed to protecting it through this Privacy Policy. This document outlines how we collect, use, store, and protect your information when you use our SaaS platform (KW Toolbox). By accessing or using KW Toolbox, you agree to the terms of this Privacy Policy. If you do not agree, please do not use our platform.
          </p>

          {/* Section 1 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">1. Information We Collect</h2>
          <p>We only collect the minimum amount of personal information necessary to provide our services effectively. Email Address: We collect your email address when you sign up, contact us, or otherwise interact with our platform.</p>

          <h3 className="text-lg font-semibold mt-6 mb-3">1.1 Why We Collect Your Email Address</h3>
          <p>We use your email address to:</p>
          <ul className="list-disc pl-6 mb-6">
            <li>Create and manage your account</li>
            <li>Provide customer support</li>
            <li>Send software updates, product information, or important notifications</li>
            <li>Communicate about changes to our Terms of Use or Privacy Policy</li>
          </ul>

          {/* Section 2 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">2. How We Use Your Information</h2>
          <p>We use your email address only for the purposes outlined in this policy. We do not sell, rent, or share your email address with third parties, except:</p>
          <ul className="list-disc pl-6 mb-6">
            <li>To provide core platform functionality: Necessary for account access and communication</li>
            <li>When required by law: If legally required (e.g., subpoenas or government requests)</li>
          </ul>

          {/* Section 3 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">3. Data Storage and Security</h2>
          <p>We take reasonable measures to protect your information from unauthorized access, alteration, disclosure, or destruction.</p>

          <h3 className="text-lg font-semibold mt-6 mb-3">3.1 Security Measures Include:</h3>
          <ul className="list-disc pl-6 mb-6">
            <li>Encryption of data during transmission (SSL/TLS)</li>
            <li>Secure storage using reputable cloud hosting providers</li>
            <li>Restricted access to authorized personnel only</li>
          </ul>

          <h3 className="text-lg font-semibold mt-6 mb-3">3.2 Data Retention Policy:</h3>
          <p>Your email address will be stored for as long as your account is active. If you request deletion, your email address will be permanently removed from our systems within 30 days, unless legal obligations require us to retain it.</p>

          {/* Section 4 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">4. Your Rights and Choices</h2>
          <p>You have the right to:</p>
          <ul className="list-disc pl-6 mb-6">
            <li>Access Your Information: Request access to the personal information we store</li>
            <li>Update Your Information: Correct any inaccuracies in your personal data</li>
            <li>Request Deletion: Ask us to delete your email address from our systems</li>
            <li>Opt-Out of Communications: Unsubscribe from marketing emails via the unsubscribe link included in every message</li>
          </ul>

          {/* Section 5 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">5. Third-Party Services</h2>
          <p>We may use trusted third-party service providers (e.g., cloud hosting platforms, analytics tools) to operate our SaaS platform. These third parties are contractually obligated to protect your data and cannot use your information for their own purposes.</p>

          {/* Section 6 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">6. Cookies and Tracking Technologies</h2>
          <p>Our platform does not currently use cookies or tracking technologies to collect personal data. If this changes in the future, this Privacy Policy will be updated, and you will be notified.</p>

          {/* Section 7 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">7. Children's Privacy</h2>
          <p>Our platform is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If we learn that we have inadvertently collected information from a child under 13, we will promptly delete it.</p>

          {/* Section 8 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">8. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices, services, or applicable laws. If we make significant changes, we will notify you via email or through a notice on our platform. Your continued use of KW Toolbox after updates constitutes acceptance of the revised Privacy Policy.</p>

          {/* Section 9 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">9. Contact Us</h2>
          <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us.</p>

          {/* Section 10 */}
          <h2 className="text-xl font-semibold mt-8 mb-4">10. Acceptance of Privacy Policy</h2>
          <p>By using KW Toolbox, you signify that you have read, understood, and agree to this Privacy Policy.</p>
        </div>
      </div>
    </div>
  );
} 