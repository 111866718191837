import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  IconButton,
  Chip,
  Card,
  CardContent,
  Button,
  Stack,
} from '@mui/material';
import {
  MarkEmailRead as MarkReadIcon,
  MarkunreadOutlined as UnreadIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

export default function Alerts() {
  // Sample alerts data - in production, this would come from your backend
  const [alerts, setAlerts] = useState([
    {
      id: 1,
      message: "Plant A has exceeded temperature threshold",
      timestamp: "2024-03-20T10:00:00",
      status: "unread",
      type: "warning"
    },
    {
      id: 2,
      message: "Maintenance scheduled for Plant B",
      timestamp: "2024-03-19T15:30:00",
      status: "read",
      type: "info"
    },
    {
      id: 3,
      message: "Critical: System failure in Plant C",
      timestamp: "2024-03-18T09:15:00",
      status: "unread",
      type: "error"
    }
  ]);

  const handleMarkRead = (alertId) => {
    setAlerts(alerts.map(alert => 
      alert.id === alertId ? { ...alert, status: 'read' } : alert
    ));
  };

  const handleMarkUnread = (alertId) => {
    setAlerts(alerts.map(alert => 
      alert.id === alertId ? { ...alert, status: 'unread' } : alert
    ));
  };

  const handleDelete = (alertId) => {
    setAlerts(alerts.filter(alert => alert.id !== alertId));
  };

  const getChipColor = (type) => {
    switch (type) {
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'info':
        return 'info';
      default:
        return 'default';
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" gutterBottom>
          Alerts
        </Typography>
        <Button 
          variant="outlined" 
          onClick={() => setAlerts(alerts.map(alert => ({ ...alert, status: 'read' })))}
        >
          Mark All as Read
        </Button>
      </Stack>

      <List>
        {alerts.map((alert) => (
          <Card key={alert.id} sx={{ mb: 2 }}>
            <CardContent>
              <ListItem
                disableGutters
                secondaryAction={
                  <Box>
                    {alert.status === 'unread' ? (
                      <IconButton onClick={() => handleMarkRead(alert.id)}>
                        <MarkReadIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleMarkUnread(alert.id)}>
                        <UnreadIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={() => handleDelete(alert.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                }
              >
                <Stack spacing={1}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Chip 
                      label={alert.type.toUpperCase()} 
                      color={getChipColor(alert.type)}
                      size="small"
                    />
                    {alert.status === 'unread' && (
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          bgcolor: 'primary.main',
                        }}
                      />
                    )}
                  </Box>
                  <Typography>{alert.message}</Typography>
                  <Typography variant="caption" color="text.secondary">
                    {new Date(alert.timestamp).toLocaleString()}
                  </Typography>
                </Stack>
              </ListItem>
            </CardContent>
          </Card>
        ))}
      </List>
    </Box>
  );
} 